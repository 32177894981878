import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import UNIVERSAL from "../../config/config";
import { api } from "src/libs/api";
import { reauthenticateUser } from "../authentication/authentication";

type InitialState = {
  data: any;
  filter: any;
  loading: boolean;
  error: any;
};

type GetInsightPayload = {
  euid: string;
  ad_account: string;
  campaign_id: string;
  ad_id: string;
  preset: string;
  adset_id: string;
  date_start: string;
  date_stop: string;
  status: string;
};

const initialState: InitialState = {
  data: "",
  filter: "",
  loading: true,
  error: "",
};

export const getAllFilters = createAsyncThunk(
  "/insight/getAllFilters",
  (data: GetInsightPayload, { rejectWithValue }) => {
    return api(`${UNIVERSAL.BASEURL}/api/v2/facebook/filter`, {
      method: "POST",
      body: JSON.stringify({
        euid: `${data.euid}`,
        account_id: `${data.ad_account}`,
        campaign_id: data.campaign_id === "0" ? "" : data.campaign_id,
        ad_id: data.ad_id === "0" ? "" : data.ad_id,
        date_preset: data?.preset ? data?.preset : "",
        adset_id: data.adset_id === "0" ? "" : data.adset_id,
        date_start: !data?.preset ? data?.date_start?.split("T")[0] : "" || "",
        date_stop: !data?.preset ? data?.date_stop?.split("T")[0] : "" || "",
        status: data.status === "ALL" ? "" : data.status,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (!res.success) return rejectWithValue(res);
        if (res.success) return res;
      });
  }
);

export const getInsights = createAsyncThunk(
  "/insight/getInsights",
  (data: GetInsightPayload, { rejectWithValue }) => {
    return api(`${UNIVERSAL.BASEURL}/api/v2/facebook/campaign_insights`, {
      method: "POST",
      body: JSON.stringify({
        euid: `${data.euid}`,
        account_id: `${data.ad_account}`,
        campaign_id: data.campaign_id === "0" ? "" : data.campaign_id,
        ad_id: data.ad_id === "0" ? "" : data.ad_id,
        date_preset: data?.preset ? data?.preset : "",
        adset_id: data.adset_id === "0" ? "" : data.adset_id,
        date_start: !data?.preset ? data?.date_start?.split("T")[0] : "",
        date_stop: !data?.preset ? data?.date_stop?.split("T")[0] : "",
        status: data.status === "ALL" ? "" : data.status,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (!res.success) return rejectWithValue(res);
        if (res.success) return res;
      });
  }
);

const insightSlice = createSlice({
  name: "insight",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(reauthenticateUser, () => initialState);
    builder.addCase(getInsights.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getInsights.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = "";
    });
    builder.addCase(getInsights.rejected, (state, action) => {
      state.loading = false;
      state.data = "";
      state.error = action?.payload;
    });
    builder.addCase(getAllFilters.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllFilters.fulfilled, (state, action) => {
      state.loading = false;
      state.filter = action.payload;
      state.error = "";
    });
    builder.addCase(getAllFilters.rejected, (state, action) => {
      state.loading = false;
      state.data = "";
      state.error = action?.payload;
    });
  },
});

export default insightSlice.reducer;
