import { useState, useEffect } from "react";
import {
  AdAccountContainer,
  SelectAdAccountContainer,
  SubsExp,
  SubsExpBox,
  SubsExpBoxFlex,
  WalletContent,
  Wrapper,
} from "./Wallet.styles";
import BackArrowIcon from "../../assets/LeftArrow.svg";
import { useAppSelector } from "src/hooks/useAppSelector";
import { CircularProgress } from "@mui/material";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import { getTransactions } from "src/slices/wallet/walletSlice";
import WalletDropDown from "src/components/walletDropdown/WalletDropDown";
import { WalletProps } from "./types";
import { Link, useLocation } from "react-router-dom";
import AdAccountCard from "src/components/AdAccountCard/AdAccountCard";
import CloseCircleIcon from "../../assets/close-circle.svg";
import { CrossIconContainer } from "src/components/walletDropdown/WalletDropDown.styles";
import UpgradePlanIcon from "../../assets/UpgradePlan.svg";
import PlanExpImg from "../../assets/PlanExp.svg";
import WalletDropDownGlobal from "src/components/WalletDropDownGlobal/WalletDropDownGlobal";
import { createStyles } from "src/config/theme";

const Wallet = ({
  change_ad_account,
  allAdAccount,
  adAccountLoading,
}: WalletProps) => {
  const location = useLocation();
  const user = useAppSelector((state) => state.user.user);
  const wallet = useAppSelector((state) => state.wallet.data);
  const [transactionStatus, setTransactionStatus] = useState("allTransaction");
  const [filteredList, setFilteredList] = useState<any[]>([]);
  const [addMoneyModal, setAddMoneyModal] = useState(false);
  const [isShowSubsExp, setIsShowSubsExp] = useState(false);
  const [showPaymentSuccessModal, setShowPaymentSuccessModal] = useState(false);
  const [showPaymentFailedModal, setShowPaymentFailedModal] = useState(false);

  const [stepNumber, setStepNumber] = useState<number>(1);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!user?.data?.euid) return;
    dispatch(
      getTransactions({
        euid: user?.data?.euid,
      })
    );
  }, [user?.data?.euid]);

  useEffect(() => {
    if (user?.data?.subscription_flag === 0) {
      setIsShowSubsExp(true);
    }
  }, [user?.data?.subscription_flag]);

  useEffect(() => {
    if (wallet?.transactions?.length) {
      if (transactionStatus === "allTransaction") {
        setFilteredList([...wallet.transactions].reverse());
      } else if (transactionStatus === "paid") {
        setFilteredList(
          wallet?.transactions
            ?.filter((item: any) => item.txn_status === "paid")
            .reverse()
        );
      } else if (transactionStatus === "added") {
        setFilteredList(
          wallet?.transactions
            ?.filter((item: any) => item.txn_status === "created")
            .reverse()
        );
      }
    }
  }, [wallet?.transactions, transactionStatus]);

  useEffect(() => {
    if (!location.search) return;
    var query = location.search.substr(1);
    var result: any = {};
    query.split("&").forEach(function (part: any) {
      var item: any = part.split("=");
      result[item[0]] = decodeURIComponent(item[1]);
    });
    if (result.adspend === "success") {
      setShowPaymentSuccessModal(true);
      setStepNumber(2);
    } else if (result.adspend === "failure") {
      setShowPaymentFailedModal(true);
      setStepNumber(2);
    }
  }, [location.search]);

  return (
    <Wrapper
      initial={{ y: 60, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: -60, opacity: 0 }}
      transition={{ ease: "easeInOut", duration: 0.5, delay: 0.1 }}
    >
      {stepNumber === 1 ? (
        <AdAccountContainer>
          <h2>Ad Account Details</h2>
          <p>Add money to your Ad account to keep your ads running</p>
          <SelectAdAccountContainer>
            <h5>Select Ad Account</h5>
            <hr />
            {adAccountLoading ? (
              <div style={styles.circularProgressContainer}>
                <CircularProgress
                  style={styles.circularProgressStyle}
                  size={40}
                />
              </div>
            ) : allAdAccount && allAdAccount.length > 0 ? (
              allAdAccount.map((account: any) => (
                <AdAccountCard
                  account={account}
                  change_ad_account={change_ad_account}
                  setStepNumber={setStepNumber}
                  euid={user.data.euid}
                />
              ))
            ) : (
              <div style={styles.text}>No Ad Account Found!</div>
            )}
          </SelectAdAccountContainer>
        </AdAccountContainer>
      ) : (
        <>
          <WalletContent>
            <img
              src={BackArrowIcon}
              alt="back-icon"
              onClick={() => setStepNumber(1)}
            />
            <h4>Add money to the wallet</h4>
          </WalletContent>
          {user.data?.currency ? (
            String(user.data.currency).toLowerCase() === "inr" ? (
              <WalletDropDown />
            ) : (
              <WalletDropDownGlobal />
            )
          ) : (
            ""
          )}
          <SubsExp open={isShowSubsExp} onClose={() => setIsShowSubsExp(false)}>
            <SubsExpBox>
              <CrossIconContainer>
                <img
                  src={CloseCircleIcon}
                  alt="cross-icon"
                  onClick={() => setIsShowSubsExp(false)}
                />
              </CrossIconContainer>
              <SubsExpBoxFlex>
                <div>
                  <img src={PlanExpImg} alt="error-icon" />
                  <div>
                    <h2>Subscribe to get started</h2>
                    <p>Upgrade now to run campaigns with unlimited ad spends</p>
                  </div>
                </div>
                <Link to="/subscription">
                  <img src={UpgradePlanIcon} alt="upgrade-plan-icon" />{" "}
                  Subscribe
                </Link>
              </SubsExpBoxFlex>
            </SubsExpBox>
          </SubsExp>
        </>
      )}
    </Wrapper>
  );
};

export default Wallet;

const styles = createStyles({
  circularProgressContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  circularProgressStyle: {
    color: "white",
  },
  text: {
    color: "white",
    textAlign: "center",
    fontSize: "1.2rem",
  },
});
