import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import UNIVERSAL from "../../config/config";
import { api } from "src/libs/api";
import { reauthenticateUser } from "../authentication/authentication";

type InitialState = {
  data: any;
  loading: boolean;
  error: any;
  companyDetail: any;
};

type AddCompanyDetailPayload = {
  contact: string;
  countryDailCode: string;
  euid: string;
  website: string;
};

const initialState: InitialState = {
  data: "",
  loading: false,
  error: "",
  companyDetail: "",
};

export const addCompanyDetail = createAsyncThunk(
  "/addCompanyDetail",
  (data: AddCompanyDetailPayload, { rejectWithValue }) => {
    return api(`${UNIVERSAL.BASEURL}/api/v1/auth/onboarding`, {
      method: "POST",
      body: JSON.stringify({
        ...data,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (!res.success) return rejectWithValue(res);
        if (res.success) return res;
      });
  }
);

const addCompanyDetailSlice = createSlice({
  name: "addCompanyDetail",
  initialState,
  reducers: {
    setCompanyDetails: (state, action) => {
      state.companyDetail = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(reauthenticateUser, () => initialState);
    builder.addCase(addCompanyDetail.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addCompanyDetail.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
      state.error = "";
    });
    builder.addCase(addCompanyDetail.rejected, (state, action) => {
      state.loading = false;
      state.data = "";
      state.error = action?.payload;
    });
  },
});

export default addCompanyDetailSlice.reducer;
export const { setCompanyDetails } = addCompanyDetailSlice.actions;
