import React, { useEffect, useRef, useState } from "react";
import {
  AppSumoModalBox,
  AppSumoModalHeader,
  AppSumoPlanCard,
  AppSumoSubscriptionCardContainer,
  AppSumoSubscriptionContainer,
  AppSumoSubscriptionModalContainer,
  AppSumoSubscriptionUpgradeModal,
  CircularProgressContainer,
  OverageChargesAppSumoModal,
  OverageChargesAppSumoModalBox,
  OverageChargesAppSumoModalHeader,
  OverageChargesAppSumoTableContainer,
  PricingElement,
} from "./AppSumoSubscription.styles";
import { useSnackbar } from "notistack";
import {
  useActiveUserSubscriptionMutation,
  useUpdateSubscriptionAppSumoMutation,
} from "src/api/subscription";
import { useAppSelector } from "src/hooks/useAppSelector";
import { format, parseISO } from "date-fns";
import GreenTick from "../../assets/Tick.png";
import CloseCircleIcon from "../../assets/close-circle.svg";
import { CircularProgress } from "@mui/material";
import OverageChargesAppSumoDataTable from "src/components/Table/OverageChargesAppSumo/DataTable";
import { OverageChargesAppSumoData } from "src/components/SubscriptionCard/OverageChargesData";
import { createStyles } from "src/config/theme";
import { formatPlanId } from "src/libs/utils";

type AppSumoSubscriptionProps = {
  data: any;
  activePlan: any;
  isUserSubsLoading: boolean;
  isSubscriptionDetailDataLoading: boolean;
  isSubsSuccess: boolean;
  isSubscriptionDetailSuccess: boolean;
};

const AppSumoSubscription = ({
  data,
  activePlan,
  isUserSubsLoading,
  isSubscriptionDetailDataLoading,
  isSubsSuccess,
  isSubscriptionDetailSuccess,
}: AppSumoSubscriptionProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const user = useAppSelector((state) => state.user.user);

  const AppSumoSubsCardRef = useRef<HTMLDivElement | null>(null);
  const [isDataReady, setIsDataReady] = useState(false);

  const [showSubscriptionUpdateModal, setShowSubscriptionUpgradeModal] =
    useState(false);
  const [isShowingOveragesAppSumoModal, setIsShowingOveragesAppSumoModal] =
    useState(false);
  const [isAppSumoSubProcessing, setIsAppSumoSubProcessing] = useState({
    loading: false,
    planId: "",
  });

  const [activeUserSubscription] = useActiveUserSubscriptionMutation();
  const [updateSubscriptionAppSumo] = useUpdateSubscriptionAppSumoMutation();

  const handleAppSumoPlan = async (selectedPlan: any) => {
    setIsAppSumoSubProcessing({ loading: true, planId: selectedPlan?.plan_id });
    try {
      await activeUserSubscription({
        euid: user.data.euid,
        ad_account_id: user.data.fb_ad_account ? user.data.fb_ad_account : "",
      })
        .unwrap()
        .then(async (activeuserSubscriptionRes: any) => {
          const todayDate: any = new Date();
          const subscriptionExpiryData: any = new Date(
            activeuserSubscriptionRes[0]?.expiry_at
          );
          const differenceInMs = subscriptionExpiryData - todayDate;
          const differenceInDays = differenceInMs / (1000 * 3600 * 24);
          if (activeuserSubscriptionRes.length > 0 && differenceInDays >= 5) {
            try {
              await updateSubscriptionAppSumo({
                ad_account_id: user.data.fb_ad_account
                  ? user.data.fb_ad_account
                  : "",
                amount: 0,
                email: user.data.email,
                euid: user.data.euid,
                plan_id: selectedPlan.plan_id,
                subscription_id: activeuserSubscriptionRes[0].subscription_id,
                tax: 0,
                total_amount: selectedPlan.usd_amount,
              })
                .unwrap()
                .then((response: any) => {
                  if (activePlan) {
                    if (
                      Number(activePlan.price_range) > Number(data.price_range)
                    ) {
                      enqueueSnackbar(
                        `Your plan will be downgraded on  ${
                          activePlan.expires_date &&
                          format(
                            parseISO(activePlan.expires_date),
                            "dd MMM yyyy"
                          )
                        }`,
                        {
                          variant: "success",
                        }
                      );
                    } else {
                      if (response.payment_link) {
                        window.open(response.payment_link, "_self");
                      } else {
                        setShowSubscriptionUpgradeModal(true);
                      }
                    }
                  } else {
                    enqueueSnackbar("You have been updated to the new plan.", {
                      variant: "success",
                    });
                  }
                  setIsAppSumoSubProcessing({
                    loading: false,
                    planId: "",
                  });
                });
            } catch (e: any) {
              console.log(e);
              if (e.data.message) {
                enqueueSnackbar(e.data.message, {
                  variant: "error",
                });
              } else {
                enqueueSnackbar("Something went wrong", {
                  variant: "error",
                });
              }
              setIsAppSumoSubProcessing({
                loading: false,
                planId: "",
              });
            }
          }
        });
    } catch (e: any) {
      console.log(e);
      if (e.data.message) {
        enqueueSnackbar(e.data.message, {
          variant: "error",
        });
      } else {
        enqueueSnackbar("Something went wrong", {
          variant: "error",
        });
      }
      setIsAppSumoSubProcessing({
        loading: false,
        planId: "",
      });
    }
    setIsAppSumoSubProcessing({
      loading: false,
      planId: "",
    });
  };

  useEffect(() => {
    if (
      !isUserSubsLoading &&
      !isSubscriptionDetailDataLoading &&
      isSubsSuccess &&
      isSubscriptionDetailSuccess
    ) {
      setTimeout(() => {
        setIsDataReady(true);
      }, 1000);
    }
  }, [
    isUserSubsLoading,
    isSubscriptionDetailDataLoading,
    isSubsSuccess,
    isSubscriptionDetailSuccess,
  ]);

  useEffect(() => {
    if (isDataReady && AppSumoSubsCardRef.current) {
      AppSumoSubsCardRef.current.focus();
      AppSumoSubsCardRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [isDataReady]);

  if (
    isUserSubsLoading ||
    isSubscriptionDetailDataLoading ||
    !isSubsSuccess ||
    !isSubscriptionDetailSuccess
  ) {
    return (
      <CircularProgressContainer>
        <CircularProgress style={styles.circularProgressStyle} />
      </CircularProgressContainer>
    );
  }

  return (
    <AppSumoSubscriptionContainer>
      <h4>Pick the right plan for your ad budget</h4>
      <AppSumoSubscriptionCardContainer>
        {data?.map((data: any) => (
          <AppSumoPlanCard
            ref={
              activePlan &&
              new Date(activePlan?.expires_date) > new Date() &&
              Number(data?.usd_amount) === Number(activePlan?.usd_amount)
                ? AppSumoSubsCardRef
                : null
            }
            disable={
              activePlan &&
              new Date(activePlan?.expires_date) > new Date() &&
              Number(data?.usd_amount) === Number(activePlan?.usd_amount)
            }
            key={data?.plan_id}
          >
            <h3>{formatPlanId(data?.plan_id)}</h3>
            <p>One-Time purchase of</p>
            <PricingElement>${data?.usd_amount}</PricingElement>{" "}
            <button
              onClick={() => handleAppSumoPlan(data)}
              disabled={
                (activePlan &&
                  Number(data?.usd_amount) === Number(activePlan?.usd_amount) &&
                  new Date(activePlan?.expires_date) > new Date()) ||
                (isAppSumoSubProcessing.loading === true &&
                  isAppSumoSubProcessing.planId === data?.plan_id)
              }
            >
              {isAppSumoSubProcessing.loading === true &&
              isAppSumoSubProcessing.planId === data?.plan_id ? (
                <CircularProgress
                  style={{ width: "1.6rem", height: "1.6rem" }}
                />
              ) : activePlan &&
                new Date(activePlan?.expires_date) > new Date() ? (
                Number(data?.usd_amount) === Number(activePlan?.usd_amount) ? (
                  "Active Plan"
                ) : Number(data?.usd_amount) <
                  Number(activePlan?.usd_amount) ? (
                  "Downgrade"
                ) : Number(data?.usd_amount) >
                  Number(activePlan?.usd_amount) ? (
                  "Upgrade"
                ) : (
                  "Proceed with this"
                )
              ) : (
                "Proceed with this"
              )}
            </button>
            <span>All features above included:</span>
            <ul>
              <li>
                <img
                  src="https://d1n7zmj0fa1o7h.cloudfront.net/zocket-ai-creative/assets/planFeatureVerifiedTick.svg"
                  alt="tick-icon"
                />{" "}
                4.5% Overage Charges
              </li>
              <li>
                <img
                  src="https://d1n7zmj0fa1o7h.cloudfront.net/zocket-ai-creative/assets/planFeatureVerifiedTick.svg"
                  alt="tick-icon"
                />{" "}
                {String(data?.plan_id).toLowerCase() === "licensetier1"
                  ? "4.5%"
                  : String(data?.plan_id).toLowerCase() === "licensetier2"
                  ? "4%"
                  : String(data?.plan_id).toLowerCase() === "licensetier3"
                  ? "3.5%"
                  : String(data?.plan_id).toLowerCase() === "licensetier4"
                  ? "3%"
                  : String(data?.plan_id).toLowerCase() === "licensetier5"
                  ? "2%"
                  : ""}{" "}
                usage fee (commission on all add spends top-up)
              </li>
              <li>
                <img
                  src="https://d1n7zmj0fa1o7h.cloudfront.net/zocket-ai-creative/assets/planFeatureVerifiedTick.svg"
                  alt="tick-icon"
                />{" "}
                {String(data?.plan_id).toLowerCase() === "licensetier1"
                  ? "5 replacement adaccounts"
                  : String(data?.plan_id).toLowerCase() === "licensetier2"
                  ? "10 replacement adaccounts"
                  : String(data?.plan_id).toLowerCase() === "licensetier3"
                  ? "15 replacement adaccounts"
                  : String(data?.plan_id).toLowerCase() === "licensetier4"
                  ? "20 replacement adaccounts"
                  : String(data?.plan_id).toLowerCase() === "licensetier5"
                  ? "Unlimited replacement adaccounts"
                  : ""}
              </li>
              <li>
                <img
                  src="https://d1n7zmj0fa1o7h.cloudfront.net/zocket-ai-creative/assets/planFeatureVerifiedTick.svg"
                  alt="tick-icon"
                />{" "}
                Instant ad spends top-ups
              </li>
              {String(data.plan_id).toLowerCase() === "licensetier3" ||
              String(data.plan_id).toLowerCase() === "licensetier4" ||
              String(data.plan_id).toLowerCase() === "licensetier5" ? (
                <li>
                  <img
                    src="https://d1n7zmj0fa1o7h.cloudfront.net/zocket-ai-creative/assets/planFeatureVerifiedTick.svg"
                    alt="tick-icon"
                  />{" "}
                  Payment mode
                </li>
              ) : (
                ""
              )}
            </ul>
          </AppSumoPlanCard>
        ))}
      </AppSumoSubscriptionCardContainer>

      <OverageChargesAppSumoModal
        open={isShowingOveragesAppSumoModal}
        onClose={() => setIsShowingOveragesAppSumoModal(false)}
      >
        <OverageChargesAppSumoModalBox>
          <OverageChargesAppSumoModalHeader>
            <h3>Overage Charges</h3>
            <img
              src={CloseCircleIcon}
              alt="circle-cross-icon"
              onClick={() => setIsShowingOveragesAppSumoModal(false)}
            />
          </OverageChargesAppSumoModalHeader>
          <OverageChargesAppSumoTableContainer>
            <OverageChargesAppSumoDataTable data={OverageChargesAppSumoData} />
          </OverageChargesAppSumoTableContainer>
        </OverageChargesAppSumoModalBox>
      </OverageChargesAppSumoModal>

      <AppSumoSubscriptionUpgradeModal
        open={showSubscriptionUpdateModal}
        onClose={() => setShowSubscriptionUpgradeModal(false)}
      >
        <AppSumoModalBox>
          <AppSumoModalHeader>
            <h3></h3>
            <img
              src={CloseCircleIcon}
              alt="circle-cross-icon"
              onClick={() => setShowSubscriptionUpgradeModal(false)}
            />
          </AppSumoModalHeader>
          <AppSumoSubscriptionModalContainer>
            <img src={GreenTick} alt="green-circle-tick-img" />
            <h4>You have been updated to the new plan.</h4>
            <p>You can start using the your current plan features.</p>
          </AppSumoSubscriptionModalContainer>
        </AppSumoModalBox>
      </AppSumoSubscriptionUpgradeModal>
    </AppSumoSubscriptionContainer>
  );
};

export default AppSumoSubscription;

const styles = createStyles({
  circularProgressStyle: {
    color: "white",
  },
});
