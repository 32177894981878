import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import UNIVERSAL from "src/config/config";
import { ResponseSuccess } from "src/interfaces/api";
import {
  loginWithEmailOTPProps,
  signupWithEmailOTPProps,
  verifyLoginWithEmailOTPProps,
  verifySignupWithEmailOTPProps,
} from "src/types/authentication";

export type loginWithGooglePayload = {
  googleJWT: string;
};

export const authenticationApi = createApi({
  reducerPath: "authenticationApi",
  baseQuery: fetchBaseQuery({
    baseUrl: UNIVERSAL.BASEURL,
  }),
  endpoints: (builder) => ({
    getLoginWithEmailOTP: builder.mutation<any, loginWithEmailOTPProps>({
      query: (body) => ({
        url: `/api/v1/auth/loginWithEmailOTP`,
        method: "POST",
        body,
      }),
      // eslint-disable-next-line
      transformResponse: (response: ResponseSuccess<any>) => {
        return response.data;
      },
    }),
    getSignupWithEmailOTP: builder.mutation<any, signupWithEmailOTPProps>({
      query: (body) => ({
        url: `/api/v1/auth/signupEmailOTP`,
        method: "POST",
        body,
      }),
      // eslint-disable-next-line
      transformResponse: (response: ResponseSuccess<any>) => {
        return response.data;
      },
    }),
    verifyLoginWithEmailOTP: builder.mutation<
      any,
      verifyLoginWithEmailOTPProps
    >({
      query: (body) => ({
        url: `/api/v1/auth/loginWithEmailOTPVerify`,
        method: "POST",
        body,
      }),
      // eslint-disable-next-line
      transformResponse: (response: ResponseSuccess<any>) => {
        return response.data;
      },
    }),
    verifySignupWithEmailOTP: builder.mutation<
      any,
      verifySignupWithEmailOTPProps
    >({
      query: (body) => ({
        url: `/api/v1/auth/signupEmailOTPVerify`,
        method: "POST",
        body,
      }),
      // eslint-disable-next-line
      transformResponse: (response: ResponseSuccess<any>) => {
        return response.data;
      },
    }),
    verifyReCaptchaToken: builder.mutation<any, { recaptcha_key: string }>({
      query: (body) => ({
        url: `/api/recaptcha/validate`,
        method: "POST",
        body,
      }),
      // eslint-disable-next-line
      transformResponse: (response: ResponseSuccess<any>) => {
        return response.data;
      },
    }),
  }),
});

export const {
  useGetLoginWithEmailOTPMutation,
  useGetSignupWithEmailOTPMutation,
  useVerifyLoginWithEmailOTPMutation,
  useVerifySignupWithEmailOTPMutation,
  useVerifyReCaptchaTokenMutation,
} = authenticationApi;
