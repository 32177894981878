import React, { useState, useEffect, useMemo } from "react";
import Dashboard from "../pages/dashboard/Dashboard";
import avatar from "src/assets/avatar.png";
import linkSuccess from "src/assets/linkSuccessful.png";
import Lottie from "lottie-react";

import {
  Wrapper,
  SideBar,
  Main,
  Logo,
  NavUl,
  NavLi,
  SideBarOuter,
  Header,
  Avatar,
  LinkSuccessFulModal,
  LinkSuccessFulModalBottom,
  SelectAdAccountDropdown,
  CustomPopover,
  CustomToolTip,
  NavUlBg,
  ChangeModal,
  DailyReport,
  DailyReportFlex,
  DailyReportList,
  StatCard,
  ImgWrap,
  HeaderLeft,
  ModalCloseBtn,
  BrandHeading,
  WalletContainer,
  FlexContainer,
  BellImgContainer,
  NotificationModal,
  ModalTopContainer,
  ModalBox,
  Notifications,
  AvatarImgOuterCircle,
  LogoutBtn,
  MainSection,
  UpgradePlanContainer,
  TrialExpContainer,
  CreateAdAccountModal,
  CreateAdAccountModalBox,
  CreateAdAccountMailModal,
  CreateAdAccountMailModalBox,
  AdAccountAccessModal,
  AdAccountAccessModalBox,
  AdAccountActivateModal,
  AdAccountActivateModalBox,
  ReAuthFbContainer,
} from "./Layout.styles";
import moment from "moment";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";

import {
  Modal,
  Tooltip,
  InputAdornment,
  IconButton,
  CircularProgress,
} from "@mui/material";
import Zoom from "@mui/material/Zoom";

import addToCartDark from "../assets/addToCartDark.svg";
import initiatedCheckoutDark from "../assets/initiatedCheckoutDark.svg";
import purchasesDark from "../assets/purchasesDark.svg";
import avgPurchasesDark from "../assets/avgPurchasesDark.svg";
import costAtcDark from "../assets/costAtcDark.svg";
import costIcDark from "../assets/costIcDark.svg";
import costPurchaseDark from "../assets/costPurchaseDark.svg";
import adSpendDark from "../assets/adSpendDark.svg";
import roasDark from "../assets/roasDark.svg";
import totalSalesDark from "../assets/totalSalesDark.svg";
import dashboardIcon from "src/assets/dashboardIcon.png";
import settingIcon from "src/assets/settingIcon.png";
import liveAdIcon from "src/assets/liveAdIcon.png";
import walletIcon from "src/assets/walletIcon.svg";
import swap from "src/assets/swap.json";
import UpgradePlanIcon from "../assets/UpgradePlan.svg";

import { Button, TextField } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  fetchUserAdAccounts,
  fetchUserProfile,
  saveFbDetails,
  setActiveAdAccount,
  setIsAdAccountChanging,
  setProfile,
} from "../slices/user/userSlice";
import { getAllFilters, getInsights } from "../slices/insight/insightSlice";
import {
  clearPrevAfter,
  setComparison,
} from "src/slices/allcampaignInsight/allCamapignInsightSlice";
import { useAppSelector } from "src/hooks/useAppSelector";
import { CurrentAdAccountState } from "./types";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import LiveAdCopies from "src/pages/liveAdCopies/LiveAdCopies";
import { getReport } from "src/slices/reports/reportsSlice";
import Settings from "src/pages/settings/Settings";
import ZocketLogo from "src/components/zocketLogo/ZocketLogo";
import { AnimatePresence } from "framer-motion";
import Wallet from "src/pages/wallet/Wallet";
import BellIcon from "../assets/Bell.svg";
import Notification from "src/components/Notification/Notification";
import Invoices from "src/pages/Invoices/Invoices";
import invoicesIcon from "../assets/invoicesIcon.svg";
import Subscription from "src/pages/Subscription/Subscription";
import { useGetAdsSpendBalanceQuery } from "src/api/wallet-balance";
import PlanExpImg from "../assets/PlanExp.svg";
import ZocketAdImg from "../assets/ZocketAdImg.svg";
import MailImg from "../assets/girl-received-mail 1.svg";
import AdAccountAccessImg from "../assets/AdAccAccess.svg";
import AdAccountActivateImg from "../assets/employees-celebrating-success 1.svg";
import {
  useGetSubscriptionPlansQuery,
  useGetUserSubscriptionDetailsQuery,
} from "src/api/subscription";
import { useSnackbar } from "notistack";
import ReauthenticateFb from "src/components/ReauthenticateFb/ReauthenticateFb";
import { fetchSymbolFromCurrency, formatPlanId } from "src/libs/utils";
import { createStyles } from "src/config/theme";
import useCrispChat from "src/hooks/useCrisp";
import { authKey } from "src/constants/storage";
import { reauthenticateUser } from "src/slices/authentication/authentication";
import store from "src/config/store";
import AppSumoSubscription from "src/pages/AppSumoSubscription/AppSumoSubscription";

const Layout = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [linkModal, setLinkModal] = useState(false);
  const [selectedAdAccount, setSelectedAdAccount] = useState<any>("");
  const [allCampaigns, setAllCampaigns] = useState<any[]>([]);
  const [allAdsets, setAllAdsets] = useState<any[]>([]);
  const [allAds, setAllAds] = useState<any[]>([]);
  const [currentAdAccount, setCurrentAdAccount] =
    useState<CurrentAdAccountState>({ ad_account_name: "", ad_account_id: "" });
  const [campaignListForTable, setCampaignListForTable] = useState([]);
  const [adAccountSearchTerm, setAdAccountSearchTerm] = useState("");
  const [isComparison, setIsComparison] = useState(false);

  const [noInsightModal, setNoInsightModal] = useState(false);
  const [linkSuccessModal, setLinkSuccessModal] = useState(false);

  const [adAccountDropdown, setAdAccountDropdown] = useState<any>(null);
  const [adAccountPopper, setAdAccountPopper] = useState(false);
  const [changeAdAccountModal, setChangeAdAccountModal] = useState(false);
  const [dailyReportModal, setDailyReportModal] = useState(false);
  const [adAccountModal, setAdAccountModal] = useState(false);
  const [isRechargeOnly, setRechargeOnly] = useState(false);

  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const [showCreateAdAccountModal, setShowCreateAdAccountModal] =
    useState(false);

  const [showCreateAdAccountMailModal, setShowCreateAdAccountMailModal] =
    useState(false);

  const [showAdAccountAccessModal, setShowAdAccountAccessModal] =
    useState(false);

  const [showAdAccountActivateModal, setShowAdAccountActivateModal] =
    useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const user = useAppSelector((state) => state.user.user);
  const userDataLoading = useAppSelector((state) => state.user.loading);
  const adAccountLoading = useAppSelector(
    (state) => state.user.fbadaccountLoading
  );
  const allAdAccount = useAppSelector((state) => state.user.userAdAccounts);
  const fbDetail = useAppSelector((state) => state.user.fb);
  const { data: insight, loading } = useAppSelector((state) => state.insight);
  const auth = useAppSelector((state) => state.authentication.security);

  const { data: reports, loading: reportLoading } = useAppSelector(
    (state) => state.reports
  );

  useCrispChat(
    user?.data?.currency
      ? user?.data?.currency === "" ||
        user?.data?.currency === null ||
        String(user?.data?.currency).toLowerCase() === "inr"
        ? ""
        : "6dca96a3-faa4-4f40-baff-b82fed162640"
      : ""
  );

  useEffect(() => {
    if (
      user &&
      user?.error === "Account id is not present" &&
      user.success === false
    ) {
      localStorage.removeItem(authKey);
      dispatch(reauthenticateUser());
      navigate("/");
    }
  }, [user]);

  useEffect(() => {
    if (!localStorage.getItem(authKey)) {
      dispatch(reauthenticateUser());
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (user?.data) {
      if (user?.data?.currency === null || user?.data?.currency === "") {
        setAdAccountModal(true);
      }
    }
  }, [user]);

  const {
    data: walletBalance,
    isLoading: isWalletBalanceLoading,
    isSuccess,
  } = useGetAdsSpendBalanceQuery({
    ad_account: user?.data?.fb_ad_account,
    euid: user?.data?.euid,
  });

  const {
    data: userSubs,
    isLoading: userSubsLoading,
    isSuccess: userSubsSuccess,
    isError: userSubsError,
  } = useGetUserSubscriptionDetailsQuery({
    ad_account: user?.data?.fb_ad_account ? user?.data?.fb_ad_account : "",
    euid: user?.data?.euid,
  });

  const {
    data: subscriptionDetailData,
    isLoading: isSubscriptionDetailDataLoading,
    isSuccess: isSubscriptionDetailDataSuccess,
  } = useGetSubscriptionPlansQuery({
    env: "Live",
    euid: user?.data?.euid,
    ad_account_id: user?.data?.fb_ad_account,
  });

  const getUTMParams = () => {
    const params = new URLSearchParams(window.location.search);
    return params.get("utm_source");
  };
  const utmSource = getUTMParams();

  const subscriptionPlanData =
    String(user?.data?.utm_source).toLowerCase() === "appsumo" ||
    String(utmSource).toLowerCase() === "appsumo"
      ? userSubsSuccess &&
        isSubscriptionDetailDataSuccess &&
        subscriptionDetailData
          ?.filter((plan: any) => {
            return plan?.app_sumo === 1;
          })
          ?.sort((a: any, b: any) => a.amount - b.amount)
          ?.map((sorted: any, index: any) => {
            return { ...sorted, index: index };
          })
      : String(user?.data?.currency).toLowerCase() === "inr" ||
        user?.data?.currency === "" ||
        user?.data?.currency === null
      ? userSubsSuccess &&
        isSubscriptionDetailDataSuccess &&
        subscriptionDetailData
          ?.filter((plan: any) => {
            if (
              userSubs &&
              userSubs?.amount.toString() === "8299" &&
              new Date(userSubs?.expires_date) > new Date()
            ) {
              return (
                plan.amount !== 0 &&
                plan.amount !== 165999 &&
                plan?.app_sumo === 0
              );
            } else {
              return (
                plan.amount !== 0 &&
                plan.amount !== 165999 &&
                plan.amount !== 8299 &&
                plan?.app_sumo === 0
              );
            }
          })
          ?.sort((a: any, b: any) => a.amount - b.amount)
          ?.map((sorted: any, index: any) => {
            return { ...sorted, index: index };
          })
      : userSubsSuccess &&
        isSubscriptionDetailDataSuccess &&
        subscriptionDetailData
          ?.filter((plan: any) => {
            if (userSubs && new Date(userSubs?.expires_date) > new Date()) {
              return (
                plan.amount >= userSubs?.amount &&
                plan.amount !== 0 &&
                plan.amount !== 165999 &&
                plan.amount !== 8299 &&
                plan?.app_sumo === 0
              );
            } else {
              return (
                plan.amount !== 0 &&
                plan.amount !== 165999 &&
                plan.amount !== 8299 &&
                plan?.app_sumo === 0
              );
            }
          })
          ?.sort((a: any, b: any) => a.amount - b.amount)
          ?.map((sorted: any, index: any) => {
            return { ...sorted, index: index };
          });

  // useEffect(() => {
  //   const urlParams = new URLSearchParams(window.location.search);
  //   let email = urlParams.get("email");
  //   const is_recharge = urlParams.get("is_recharge");

  //   email = decodeURIComponent(email || "").replace(/ /g, "+");

  //

  //   if (!user) {
  //     const email = localStorage.getItem("ENTERPRISE_DASHBOARD_EMAIL");
  //     if (email) {
  //       dispatch(fetchUserProfile({ email: email }));
  //     } else {
  //       navigate("/");
  //     }
  //   }
  // }, []);

  useEffect(() => {
    if (user?.data?.euid && user?.data?.fb_ad_account) {
      dispatch(
        getReport({
          adAccountId: user?.data?.fb_ad_account,
          adId: "",
          adsetId: "",
          campaignId: "",
          datePreset: "yesterday",
          dateStart: "",
          dateStop: "",
          euid: String(user?.data?.euid),
        })
      );
    }
  }, [user?.data?.euid, user?.data?.fb_ad_account]);

  useEffect(() => {
    setSelectedAdAccount({
      ad_account_id: user?.data?.fb_ad_account,
      ad_account_name: user?.data?.ad_account_name,
    });
    if (user?.data?.fb_ad_account && user?.data?.fb_access_token) {
      setCurrentAdAccount({
        ad_account_name: user?.data?.ad_account_name,
        ad_account_id: user?.data?.fb_ad_account,
      });

      dispatch(
        setActiveAdAccount({
          ad_account_name: user?.data?.ad_account_name,
          ad_account_id: user?.data?.fb_ad_account,
        })
      );
      // get_ad_accounts(
      //   user?.user?.fb_access_token,
      //   `/me/adaccounts?fields=account_id,id,name&access_token=${user?.user?.fb_access_token}`
      // );
    }
  }, [user]);

  // const get_ad_accounts = (accessToken: string, url: string, acc: any[]) => {
  //   /* global FB */
  //   let tmp = acc
  //   FB.api(url, (res: any) => {
  //     if (res.data.length !== 0) {
  //       tmp = [...acc, ...res.data]

  //       // res.data.map((item: any) => {
  //       //   if (item.account_id === user?.user?.fb_ad_account) {
  //       //     setCurrentAdAccount(item);
  //       //   }
  //       // });
  //       const new_url = `/me/adaccounts?fields=account_id,id,name&access_token=${accessToken}&after=${res.paging.cursors.after}`;
  //       get_ad_accounts(accessToken, new_url, tmp);
  //     } else {
  //       setAllAdAccount(tmp)
  //     }
  //   });
  //   return;
  // };

  const handle_logout = () => {
    /* global FB */
    const _window = window as any;
    const _FB = _window.FB;

    _FB.logout();
    localStorage.removeItem(authKey);
    dispatch(reauthenticateUser());
    navigate("/");
  };

  useEffect(() => {
    if (user) {
      if (!user?.data?.fb_access_token || !user?.data?.fb_page_linked) {
        navigate("/onboarding");
      } else {
        const access_token =
          user?.data?.fb_access_token || fbDetail.accessToken;

        // get_ad_accounts(access_token, `/me/adaccounts?fields=account_id,id,name&access_token=${access_token}`, []);
        dispatch(
          fetchUserAdAccounts({
            appFbUserId: user?.data?.app_fb_user_id,
            euid: String(user?.data?.euid),
          })
        );
      }
    }
  }, [user?.data?.app_fb_user_id, user?.data?.euid]);

  useEffect(() => {
    if (user?.data?.fb_ad_account && user?.data?.fb_access_token) {
      setLinkModal(false);
    } else if (
      !(user?.data?.fb_ad_account && user?.data?.fb_access_token) &&
      !userDataLoading
    ) {
      setLinkModal(true);
    }
  }, [user?.data?.fb_ad_account, user?.data?.fb_access_token, userDataLoading]);

  useEffect(() => {
    // if (insight.data) setInsightsData(insight);
    // if (!insight?.data && !loading) {
    //   console.log(!insight?.data, insight?.data, "NO INSIGHT MODAL")
    //   setNoInsightModal(true);
    // }
    if (insight?.data || loading || linkModal) setNoInsightModal(false);
  }, [insight?.data, loading, linkModal]);

  useEffect(() => {
    if (
      user?.data?.fb_ad_account &&
      user?.data?.fb_access_token
      // user?.user?.euid &&
    ) {
      const end = new Date();
      const start = new Date(new Date().setDate(end.getDate() - 30));
      dispatch(
        getAllFilters({
          euid: user?.data?.euid,
          ad_account: user?.data?.fb_ad_account,
          adset_id: "",
          ad_id: "",
          campaign_id: "",
          date_start: new Date(start).toISOString(),
          date_stop: new Date(end).toISOString(),
          preset: "last_30d",
          status: "ALL",
        })
      )
        .unwrap()
        .then((res: any) => {
          if (res?.data) {
            setAllCampaigns(res?.data?.campaigns || []);
            setAllAds(res?.data?.ads || []);
            setAllAdsets(res?.data?.adsets || []);
            setCampaignListForTable(res?.data?.campaigns || []);
          }
        })
        .catch((err) => {
          console.log(err);
        });

      dispatch(
        getInsights({
          euid: user?.data?.euid,
          ad_account: user?.data?.fb_ad_account,
          adset_id: "",
          ad_id: "",
          campaign_id: "",
          date_start: new Date(start).toISOString(),
          date_stop: new Date(end).toISOString(),
          preset: "last_30d",
          status: "ALL",
        })
      )
        .unwrap()
        .then((res: any) => {
          if (res.message === "No insights are found") {
            setNoInsightModal(true);
            setDailyReportModal(false);
          } else {
            setDailyReportModal(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [user?.data?.fb_ad_account]);

  const change_ad_account = (account: any) => {
    if (
      selectedAdAccount?.ad_account_name === "" ||
      selectedAdAccount?.ad_account_name === "-" ||
      selectedAdAccount?.ad_account_name === null ||
      !selectedAdAccount?.ad_account_name
    ) {
      enqueueSnackbar("Ad Account Name is required", {
        variant: "error",
      });
      return;
    } else if (
      selectedAdAccount?.ad_account_id === "" ||
      selectedAdAccount?.ad_account_id === "-" ||
      selectedAdAccount?.ad_account_id === null ||
      !selectedAdAccount?.ad_account_id
    ) {
      enqueueSnackbar("Ad Account Id is required", {
        variant: "error",
      });
      return;
    } else {
      setChangeAdAccountModal(true);
      dispatch(setIsAdAccountChanging(true));
      setAdAccountPopper(false);
      setSelectedAdAccount(account);
      setCurrentAdAccount(account);
      dispatch(clearPrevAfter());
      dispatch(setComparison(false));

      dispatch(
        saveFbDetails({
          account_status: account.account_status,
          ad_account_id: account?.ad_account_id,
          ad_account_name: account?.ad_account_name,
          app_fb_user_id: user?.data?.app_fb_user_id,
          currency: account.currency,
          currency_offset: account.currency_offset,
          disable_reason: account.disable_reason,
          disable_reason_display_string: account.disable_reason_display_string,
          euid: String(user?.data?.euid),
          min_daily_budget: account.min_daily_budget,
          timezone_id: account.timezone_id,
          timezone_offset_hours_utc: account.timezone_offset_hours_utc,
          timezone_name: account.timezone_name,
        })
      )
        .unwrap()
        .then((res) => {
          dispatch(setActiveAdAccount(account));
          dispatch(fetchUserProfile());
          setChangeAdAccountModal(false);
          dispatch(setIsAdAccountChanging(false));
          dispatch(clearPrevAfter());
          setIsComparison(false);
        })
        .catch((error) => console.log(error));
    }
  };

  const filteredAdAccountList = useMemo(() => {
    if (allAdAccount && allAdAccount.length > 0) {
      if (adAccountSearchTerm === "") {
        const tmp = [...allAdAccount];
        return tmp.sort((b, a) =>
          b?.ad_account_name
            ?.toLowerCase()
            .localeCompare(a?.ad_account_name.toLowerCase())
        );
      }
      const tmp = [...allAdAccount];
      return tmp
        .filter((item) => {
          return (
            item.ad_account_name
              .toLowerCase()
              .includes(adAccountSearchTerm.toLowerCase()) ||
            item.ad_account_id.includes(adAccountSearchTerm)
          );
        })
        .sort((b, a) =>
          b?.ad_account_name
            ?.toLowerCase()
            .localeCompare(a?.ad_account_name.toLowerCase())
        );
    }
  }, [adAccountSearchTerm, allAdAccount]);

  let index =
    !isSubscriptionDetailDataLoading &&
    !userSubsLoading &&
    userSubsSuccess &&
    subscriptionPlanData?.findIndex(
      (currData: any) => currData?.price_range === userSubs?.price_range
    );

  let appSumoIndex =
    !isSubscriptionDetailDataLoading &&
    !userSubsLoading &&
    userSubsSuccess &&
    subscriptionPlanData?.findIndex(
      (currData: any) =>
        Number(currData?.usd_amount) === Number(userSubs?.usd_amount)
    );

  const generateSidebarHighlightTop = () => {
    return location.pathname === "/subscription"
      ? "17rem"
      : location.pathname.startsWith("/dashboard")
      ? "0rem"
      : location.pathname === "/live_ad_copies"
      ? "3.5rem"
      : // : location.pathname === "/campaign"
      location.pathname === "/wallet"
      ? "7rem"
      : // : location.pathname === "/wallet"
      location.pathname === "/transaction"
      ? "10.5rem"
      : "14rem";
  };

  return (
    <Wrapper>
      <Header>
        <Logo>
          {/* <img src={zocketLogo} /> */}
          {/* <ZocketLogo width="2.6rem" initialPos="90" />
          <BrandHeading>Zocket</BrandHeading> */}
          <img
            src="https://drbgg29kvmub6.cloudfront.net/assets/animationFiles/companylogo.svg"
            alt="zocket-logo"
          />
        </Logo>
        <HeaderLeft>
          {/* {!user?.data?.fb_ad_account && (
            <Button onClick={() => setAdAccountModal(true)} variant="contained">
              Link ad account
            </Button>
          )} */}

          <Tooltip
            TransitionComponent={Zoom}
            arrow
            title={<CustomToolTip>Wallet</CustomToolTip>}
          >
            <Link to="/wallet" style={{ textDecoration: "none" }}>
              <WalletContainer>
                <img src={walletIcon} alt="wallet-icon" />
                <FlexContainer>
                  <p>Balance: </p>
                  <h6>
                    {user?.data?.currency
                      ? user?.data?.currency === "inr" ||
                        user?.data?.currency === "" ||
                        user?.data?.currency === null
                        ? "₹"
                        : fetchSymbolFromCurrency(user?.data?.currency)
                      : "₹"}
                    {isWalletBalanceLoading
                      ? "Loading..."
                      : isSuccess
                      ? Number(walletBalance?.balance).toFixed(2)
                      : "0"}
                  </h6>
                </FlexContainer>
              </WalletContainer>
              {/* <WalletBalanceAddText>
                Recently Added : 15,000 | 23 Nov 2023
              </WalletBalanceAddText> */}
            </Link>
          </Tooltip>
          <Link to="/subscription" style={{ textDecoration: "none" }}>
            {String(user?.data?.utm_source).toLowerCase() === "appsumo" ||
            String(utmSource).toLowerCase() === "appsumo" ? (
              <UpgradePlanContainer>
                <img src={UpgradePlanIcon} alt="upgrade-plan-icon" />
                {userSubsLoading
                  ? "Loading..."
                  : new Date() > new Date(userSubs?.expires_date) ||
                    userSubs === null
                  ? "Explore Plans"
                  : userSubsSuccess
                  ? `${formatPlanId(userSubs?.plan_id)}`
                  : "Explore Plans"}{" "}
              </UpgradePlanContainer>
            ) : (
              <UpgradePlanContainer>
                <img src={UpgradePlanIcon} alt="upgrade-plan-icon" />
                {userSubsLoading
                  ? "Loading..."
                  : new Date() > new Date(userSubs?.expires_date) ||
                    userSubs === null
                  ? "Explore Plans"
                  : userSubsSuccess
                  ? `$${userSubs?.usd_amount} Plan`
                  : "Explore Plans"}{" "}
              </UpgradePlanContainer>
            )}
          </Link>

          {user?.data?.fb_ad_account && user?.data?.fb_access_token && (
            <>
              {/* Daily Report */}
              {/* <Tooltip
                TransitionComponent={Zoom}
                arrow
                title={<CustomToolTip>Daily Report</CustomToolTip>}
              >
                <img
                  onClick={() => setDailyReportModal(true)}
                  src={dailyReportDark}
                />
              </Tooltip> */}
              <Tooltip
                TransitionComponent={Zoom}
                arrow
                title={
                  <CustomToolTip>
                    {currentAdAccount.ad_account_name}
                  </CustomToolTip>
                }
              >
                <TextField
                  autoComplete="false"
                  size="medium"
                  value={currentAdAccount.ad_account_name}
                  onClick={(e) => {
                    setAdAccountPopper(true);
                    setAdAccountDropdown(e.currentTarget);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <ArrowDropDownIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Tooltip>
              {/* {allAdAccount?.map(item => {
            return <MenuItem key={item.account_id} value={item.name}>{item.name}</MenuItem>
          })}

        </TextField> */}
              <CustomPopover
                sx={{ borderRadius: 20 }}
                open={adAccountPopper}
                onClose={() => {
                  setAdAccountPopper(false);
                  setAdAccountSearchTerm("");
                }}
                anchorEl={adAccountDropdown}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
              >
                <SelectAdAccountDropdown>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder="Search by ad account name or ID..."
                    value={adAccountSearchTerm}
                    onChange={(e) => setAdAccountSearchTerm(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                  {adAccountLoading && (
                    <div style={styles.loaderContainer}>
                      <CircularProgress
                        size={20}
                        style={styles.circularProgressStyle}
                      />
                    </div>
                  )}
                  <ul>
                    {filteredAdAccountList?.length !== 0 &&
                      filteredAdAccountList?.map((item) => {
                        return (
                          <li
                            onClick={() => change_ad_account(item)}
                            key={item.ad_account_id}
                          >
                            <p>{item.ad_account_name || "-"}</p>
                            <span>{item.ad_account_id || "-"}</span>
                          </li>
                        );
                      })}
                    {filteredAdAccountList?.length === 0 && (
                      <p>No ad account to show!</p>
                    )}
                  </ul>
                  <Button variant="outlined">Link more account</Button>
                </SelectAdAccountDropdown>
              </CustomPopover>
            </>
          )}

          {/* <Tooltip
            TransitionComponent={Zoom}
            arrow
            title={<CustomToolTip>Notifications</CustomToolTip>}
          >
            <BellImgContainer onClick={() => setShowNotificationModal(true)}>
              <img src={BellIcon} alt="notification-icon" />
              <span />
            </BellImgContainer>
          </Tooltip> */}

          {/* <NotificationModal
            open={showNotificationModal}
            onClose={() => setShowNotificationModal(false)}
          >
            <ModalBox>
              <ModalTopContainer>
                <h3>Notification</h3>
                <div>
                  <span>Unread</span>
                  <button>Read All</button>
                </div>
              </ModalTopContainer>
              <hr />
              <Notifications>
                <Notification type="wallet-balance-low" />
                <Notification type="payment-success" />
                <Notification type="ad-account-rejected" />
              </Notifications>
            </ModalBox>
          </NotificationModal> */}
        </HeaderLeft>
      </Header>

      <Main>
        {/* Sidebar */}
        <SideBarOuter>
          <SideBar
            initial={{ x: -60, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -60, opacity: 0 }}
            transition={{ ease: "easeInOut", duration: 0.5, delay: 0.2 }}
          >
            <NavUl>
              <NavUlBg
                hide={location.pathname === "/subscription" ? true : false}
                top={generateSidebarHighlightTop()}
              />

              <Link style={{ textDecoration: "none" }} to="/dashboard">
                <NavLi>
                  <img src={dashboardIcon} alt="dashboard-icon" />
                  <p>Dashboard</p>
                </NavLi>
              </Link>

              <Link style={{ textDecoration: "none" }} to="/live_ad_copies">
                <NavLi>
                  <img src={liveAdIcon} alt="live-ad-icon" />
                  <p>Live ad copies</p>
                </NavLi>
              </Link>

              {/* <Link style={{ textDecoration: "none" }} to="/campaign">
                <NavLi>
                  <img src={campaignIcon} alt="campaign-icon" />
                  <p>Campaign</p>
                </NavLi>
              </Link> */}
              {/* <Link style={{ textDecoration: "none" }} to="/set_rules">
                <NavLi>
                  <img src={addRulesIcon} />
                  <p>Set your rules</p>
                </NavLi>
              </Link> */}
              <Link style={{ textDecoration: "none" }} to="/wallet">
                <NavLi>
                  <img src={walletIcon} alt="wallet-icon" />
                  <p>My wallet</p>
                </NavLi>
              </Link>
              <Link style={{ textDecoration: "none" }} to="/transaction">
                <NavLi>
                  <img src={invoicesIcon} alt="invoices-icon" />
                  <p>Transactions</p>
                </NavLi>
              </Link>

              <Link style={{ textDecoration: "none" }} to="/settings">
                <NavLi>
                  <img src={settingIcon} alt="settings-icon" />
                  <p>Settings</p>
                </NavLi>
              </Link>
            </NavUl>
            <Avatar>
              <AvatarImgOuterCircle>
                <div>
                  <img src={avatar} />
                </div>
              </AvatarImgOuterCircle>
              <h3>{user?.data?.business_name}</h3>
              <p>{user?.data?.email}</p>
              <LogoutBtn onClick={() => handle_logout()}>Logout</LogoutBtn>
            </Avatar>
          </SideBar>
        </SideBarOuter>
        <AnimatePresence>
          <MainSection>
            {user &&
              !isRechargeOnly &&
              user?.data?.subscription_flag === 0 &&
              location.pathname !== "/subscription" && (
                <TrialExpContainer>
                  <div>
                    <img src={PlanExpImg} alt="plan-expired-icon" />
                    <div>
                      <h2>Subscribe to get started</h2>
                      <h3>
                        Upgrade now to run campaigns with unlimited ad spends
                      </h3>
                    </div>
                  </div>
                  <Link to="/subscription">
                    <img src={UpgradePlanIcon} alt="upgrade-plan-icon" />{" "}
                    Explore Plans
                  </Link>
                </TrialExpContainer>
              )}
            {user &&
              !isRechargeOnly &&
              user?.data?.reauthenticate_flag === 1 && (
                <ReAuthFbContainer>
                  <div>
                    <img
                      src="https://d1n7zmj0fa1o7h.cloudfront.net/instamojo-assets/add-fb-page.svg"
                      alt="plan-expired-icon"
                    />
                    <div>
                      <h2>
                        Facebook Session expired, Please Re-Authenticate Again
                      </h2>
                      {/* <h3>
                    Upgrade to get lot of features to grow your business to next
                    level
                  </h3> */}
                    </div>
                  </div>
                  <ReauthenticateFb />
                </ReAuthFbContainer>
              )}
            {location.pathname.startsWith("/dashboard") && (
              <Dashboard
                linkModal={linkModal}
                setLinkModal={setLinkModal}
                selectedAdAccount={selectedAdAccount}
                setSelectedAdAccount={setSelectedAdAccount}
                insightsData={insight}
                noInsightModal={noInsightModal}
                setNoInsightModal={setNoInsightModal}
                setAllCampaigns={setAllCampaigns}
                allCampaigns={allCampaigns}
                allAdsets={allAdsets}
                setAllAdsets={setAllAdsets}
                allAds={allAds}
                setAllAds={setAllAds}
                campaignListForTable={campaignListForTable}
                allAdAccount={allAdAccount}
                isComparison={isComparison}
                setIsComparison={setIsComparison}
                adAccountModal={adAccountModal}
                setAdAccountModal={setAdAccountModal}
                setChangeAdAccountModal={setChangeAdAccountModal}
              />
            )}
            {location.pathname === "/live_ad_copies" && <LiveAdCopies />}
            {location.pathname === "/wallet" && (
              <Wallet
                change_ad_account={change_ad_account}
                allAdAccount={allAdAccount}
                adAccountLoading={adAccountLoading}
              />
            )}
            {location.pathname === "/transaction" && <Invoices />}
            {/* {location.pathname === "/campaign" && <Campaign />} */}
            {location.pathname === "/settings" && (
              <Settings utmSource={String(utmSource)} />
            )}
            {location.pathname === "/subscription" && (
              <>
                {String(user?.data?.utm_source).toLowerCase() === "appsumo" ||
                String(utmSource).toLowerCase() === "appsumo" ? (
                  <AppSumoSubscription
                    data={subscriptionPlanData}
                    activePlan={userSubs}
                    isUserSubsLoading={userSubsLoading}
                    isSubscriptionDetailDataLoading={
                      isSubscriptionDetailDataLoading
                    }
                    isSubsSuccess={userSubsSuccess}
                    isSubscriptionDetailSuccess={
                      isSubscriptionDetailDataSuccess
                    }
                  />
                ) : (
                  <Subscription
                    data={subscriptionPlanData}
                    isLoading={isSubscriptionDetailDataLoading}
                    isSuccess={isSubscriptionDetailDataSuccess}
                    isSubsSuccess={userSubsSuccess}
                    activePlan={userSubs}
                    index={
                      String(user?.data?.utm_source).toLowerCase() ===
                        "appsumo" ||
                      String(utmSource).toLowerCase() === "appsumo"
                        ? appSumoIndex
                        : index
                    }
                    setChangeAdAccountModal={setChangeAdAccountModal}
                    allAdAccount={allAdAccount}
                    setAdAccountPopper={setAdAccountPopper}
                    setSelectedAdAccount={setSelectedAdAccount}
                    setCurrentAdAccount={setCurrentAdAccount}
                    setIsComparison={setIsComparison}
                  />
                )}
              </>
            )}
          </MainSection>
        </AnimatePresence>
      </Main>

      {/* Fb linking successfull */}
      <Modal open={linkSuccessModal} onClose={() => setLinkSuccessModal(false)}>
        <LinkSuccessFulModal>
          <img src={linkSuccess} />
          <LinkSuccessFulModalBottom>
            <h3>Your facebook page has been linked</h3>
            <p>You can now view the insights and start running ads in it</p>
          </LinkSuccessFulModalBottom>
        </LinkSuccessFulModal>
      </Modal>

      {/* change ad account animation */}
      <Modal open={changeAdAccountModal}>
        <ChangeModal>
          <Lottie
            style={{
              width: "10rem",
            }}
            animationData={swap}
            loop={true}
          />
          <p>Changing ad account...</p>
        </ChangeModal>
      </Modal>

      {/* Daily Report Modal */}
      {!reportLoading && !loading && (
        <Modal
          open={dailyReportModal}
          onClose={() => setDailyReportModal(false)}
        >
          <DailyReport
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
          >
            <ModalCloseBtn>
              <IconButton onClick={() => setDailyReportModal(false)}>
                <CloseIcon />
              </IconButton>
            </ModalCloseBtn>
            <h2>Yesterday&apos;s Campaign Stats</h2>
            <p>
              Please check out the daily summary report on{" "}
              <em>{moment().format("LL")}</em>
            </p>
            <DailyReportFlex>
              <DailyReportList>
                <StatCard>
                  <ImgWrap>
                    <img src={adSpendDark} />
                  </ImgWrap>
                  <div>
                    <p>Ad spends</p>
                    <h4>
                      {fetchSymbolFromCurrency(user?.data?.currency)}
                      {reports?.yesterday?.ad_spends
                        ? parseFloat(reports?.yesterday?.ad_spends).toFixed(0)
                        : "0"}
                    </h4>
                  </div>
                </StatCard>
                <StatCard>
                  <ImgWrap>
                    <img src={roasDark} />
                  </ImgWrap>
                  <div>
                    <p>ROAS</p>
                    <h4>
                      {reports?.yesterday?.roas !== 0
                        ? parseFloat(reports?.yesterday?.roas).toFixed(1)
                        : "-"}
                    </h4>
                  </div>
                </StatCard>
                <StatCard>
                  <ImgWrap>
                    <img src={addToCartDark} />
                  </ImgWrap>
                  <div>
                    <p>Add to carts</p>
                    <h4>
                      {reports?.yesterday?.add_to_carts
                        ? reports?.yesterday?.add_to_carts
                        : "0"}
                    </h4>
                  </div>
                </StatCard>
                <StatCard>
                  <ImgWrap>
                    <img src={initiatedCheckoutDark} />
                  </ImgWrap>
                  <div>
                    <p>Initiated checkout</p>
                    <h4>
                      {reports?.yesterday?.initiated_checkout
                        ? reports?.yesterday?.initiated_checkout
                        : "0"}
                    </h4>
                  </div>
                </StatCard>
                <StatCard>
                  <ImgWrap>
                    <img src={purchasesDark} />
                  </ImgWrap>
                  <div>
                    <p>Purchases</p>
                    <h4>
                      {reports?.yesterday?.purchases
                        ? reports?.yesterday?.purchases
                        : "0"}
                    </h4>
                  </div>
                </StatCard>
              </DailyReportList>
              <DailyReportList>
                <StatCard>
                  <ImgWrap>
                    <img src={totalSalesDark} />
                  </ImgWrap>
                  <div>
                    <p>Total Sales</p>
                    <h4>
                      {fetchSymbolFromCurrency(user?.data?.currency)}
                      {reports?.yesterday?.total_sales
                        ? parseFloat(reports?.yesterday?.total_sales).toFixed(0)
                        : "0"}
                    </h4>
                  </div>
                </StatCard>
                <StatCard>
                  <ImgWrap>
                    <img src={avgPurchasesDark} />
                  </ImgWrap>
                  <div>
                    <p>Avg purchase value</p>
                    <h4>
                      {fetchSymbolFromCurrency(user?.data?.currency)}
                      {reports?.yesterday?.avg_purchase
                        ? parseFloat(reports?.yesterday?.avg_purchase).toFixed(
                            0
                          )
                        : "0"}
                    </h4>
                  </div>
                </StatCard>
                <StatCard>
                  <ImgWrap>
                    <img src={costAtcDark} />
                  </ImgWrap>
                  <div>
                    <p>Cost / ATC</p>
                    <h4>
                      {fetchSymbolFromCurrency(user?.data?.currency)}
                      {reports?.yesterday?.cost_atc
                        ? parseFloat(reports?.yesterday?.cost_atc).toFixed(0)
                        : "0"}
                    </h4>
                  </div>
                </StatCard>
                <StatCard>
                  <ImgWrap>
                    <img src={costIcDark} />
                  </ImgWrap>
                  <div>
                    <p>Cost / IC</p>
                    <h4>
                      {fetchSymbolFromCurrency(user?.data?.currency)}
                      {reports?.yesterday?.cost_ic
                        ? parseFloat(reports?.yesterday?.cost_ic).toFixed(0)
                        : "0"}
                    </h4>
                  </div>
                </StatCard>
                <StatCard>
                  <ImgWrap>
                    <img src={costPurchaseDark} />
                  </ImgWrap>
                  <div>
                    <p>Cost / Purchase</p>
                    <h4>
                      {fetchSymbolFromCurrency(user?.data?.currency)}
                      {reports?.yesterday?.cost_purchase
                        ? parseFloat(reports?.yesterday?.cost_purchase).toFixed(
                            0
                          )
                        : "0"}
                    </h4>
                  </div>
                </StatCard>
              </DailyReportList>
            </DailyReportFlex>
          </DailyReport>
        </Modal>
      )}
      <CreateAdAccountModal
        open={showCreateAdAccountModal}
        onClose={() => setShowCreateAdAccountModal(false)}
      >
        <CreateAdAccountModalBox>
          <h4>To run your campaign</h4>
          <p>Choose your way to create the zocket ad account</p>
          <div>
            <h4>Zocket ad account</h4>
            <p>Run your ad with Zocket ad account</p>
            <img src={ZocketAdImg} alt="zocket-ad-img" />
            <button>Create Zocket Ad Account</button>
          </div>
        </CreateAdAccountModalBox>
      </CreateAdAccountModal>

      <CreateAdAccountMailModal
        open={showCreateAdAccountMailModal}
        onClose={() => setShowCreateAdAccountMailModal(false)}
      >
        <CreateAdAccountMailModalBox>
          <img src={MailImg} alt="mail-img" />
          <p>Mail sent to email id, Please accept to proceed</p>
        </CreateAdAccountMailModalBox>
      </CreateAdAccountMailModal>

      <AdAccountAccessModal
        open={showAdAccountAccessModal}
        onClose={() => setShowAdAccountAccessModal(false)}
      >
        <AdAccountAccessModalBox>
          <h5>Get Access On Ad Account</h5>
          <img src={AdAccountAccessImg} alt="ad-account-access-img" />
          <button>Get Ad Account Access</button>
          <p>
            Get Free trial for 7 days or Ad spends upto ₹ 10000 on your first ad
            account
          </p>
        </AdAccountAccessModalBox>
      </AdAccountAccessModal>

      <AdAccountActivateModal
        open={showAdAccountActivateModal}
        onClose={() => setShowAdAccountActivateModal(false)}
      >
        <AdAccountActivateModalBox>
          <img
            src={AdAccountActivateImg}
            alt="ad-account-activated-congrats-tick-img"
          />
          <p>Congratulation!</p>
          <p>Your ad Account has been activated</p>
          <hr />
          <p>
            You can now use the trial for <span>7 days</span> or for spend of
            upto <span>₹ 10000</span>
          </p>
        </AdAccountActivateModalBox>
      </AdAccountActivateModal>
    </Wrapper>
  );
};

export default Layout;

const styles = createStyles({
  circularProgressStyle: {
    color: "white",
  },
  loaderContainer: {
    margin: "1rem 0",
  },
});
