import dashboardBg from "../assets/dashboardBg.webp";
import styled from "styled-components";
import { Box, Modal, Popover, Typography } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { motion } from "framer-motion";

export const Wrapper = styled.section`
  background-image: url(${dashboardBg});
  background-size: cover;
  background-position: left bottom;
  padding: 1rem 2rem 1rem;
  min-height: 100vh;

  -ms-overflow-style: hidden; /* Internet Explorer 10+ */
  scrollbar-width: hidden; /* Firefox */
  ::-webkit-scrollbar {
    display: hidden; /* Safari and Chrome */
  }
  overflow: hidden;
`;

export const Header = styled.div`
  padding: 0 0 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ffffff4d;
  /* position: fixed; */
  width: 100%;

  background: linear-gradient(45deg, rgb(17 50 148 / 0%), rgb(0 28 108 / 0%));
  position: sticky;
  top: 0;
`;
// export const Main = styled.div`
//    display: flex;
//    position: relative;
// `

export const HeaderLeft = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 1.5rem;

  img {
    width: 2.5rem;
    cursor: pointer;
  }
`;

// export const DailyReportBtn = styled.div`
//   background: rgba(12, 14, 38, 0.15);
//   border: 1.5px solid rgba(64, 79, 129, 0.58);
//   backdrop-filter: blur(16px);
//   border-radius: 1rem;
// `

export const SideBarOuter = styled.div`
  width: 230px;
  min-width: 230px;
  height: 81vh;
`;

export const SideBar = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 2rem 0;
  background: rgba(12, 14, 38, 0.15);
  border: 1.5px solid rgba(64, 79, 129, 0.58);
  backdrop-filter: blur(16px);
  border-radius: 1rem;

  @media only screen and (max-width: 900px) {
    display: none;
  }
`;

export const BrandHeading = styled.h2`
  font-size: 25px;
  font-weight: 700;
`;

export const Logo = styled.div`
  display: flex;
  align-items: center;
  color: #fff;
  gap: 0.1rem;
  /* margin-top: 1.2rem; */

  img {
    margin-right: 0.8rem;
    width: 150px;
  }
`;

export const NavUl = styled.ul`
  list-style: none;
  width: 100%;
  position: relative;
`;

type NavUlBgProps = {
  top: string;
  hide: boolean;
};

export const NavUlBg = styled.div<NavUlBgProps>`
  background-color: rgba(27, 125, 255, 0.1);
  position: absolute;
  width: 100%;
  height: 3rem;
  left: 0;
  top: ${(props) => props.top};
  transition: all 0.3s ease-out;
  border-left: 4px solid #0056c6;
  display: ${(props) => (props.hide ? "none" : "block")};
`;

type NavLiProps = {
  current?: boolean;
};

export const NavLi = styled.li<NavLiProps>`
  padding: 0 1.5rem;
  height: 3rem;
  width: 100%;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  background-color: ${(props) =>
    props.current ? "rgba(27, 125, 255, 0.1)" : "none"};
  border-radius: 0.5rem;
  color: ${(props) => (props.current ? "#fff" : "#FFFFFF99")};
  font-weight: 500;
  cursor: pointer;

  p {
    margin-left: 0.4rem;
  }
`;

export const Avatar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;

  /* img {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    margin-bottom: 1rem;
    display: flex;
    border: 1px solid #fff;
  } */

  h3 {
    font-size: 16px;
    font-weight: 700;
    margin: 0.5rem 0 0.2rem 0;
    color: white;
    text-align: center;
  }

  p {
    font-size: 12px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.6);
    margin-bottom: 1rem;
  }
`;

export const AvatarImgOuterCircle = styled.div`
  height: 83px;
  width: 83px;
  border-radius: 50%;
  border: 1px solid rgba(245, 250, 255, 0.28);
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    height: 75px;
    width: 75px;
    border-radius: 50%;
    border: 1px solid rgba(245, 250, 255, 1);
  }
  > div > img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
`;

export const LogoutBtn = styled.button`
  height: 33px;
  width: 96px;
  border-radius: 10px;
  border: 1.5px solid rgba(255, 255, 255, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: white;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
`;

export const Main = styled.div`
  margin-top: 2.5rem;
  width: 100%;
  display: flex;
  gap: 2rem;

  @media only screen and (max-width: 900px) {
    padding-left: 0;
  }
`;

export const MainSection = styled.div`
  width: 100%;
  max-width: 100%;
  overflow: scroll;
  height: 81vh;

  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

export const SearchBar = styled.div`
  width: 100%;
  height: max-content;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 1rem 0.5rem;

  @media only screen and (max-width: 900px) {
    padding: 0.5rem;
  }
`;

export const SearchInput = styled.input`
  outline: none;
  width: 30rem;
  height: 2.5rem;
`;

export const SearchBarRight = styled.div`
  display: flex;
  align-items: center;

  p {
    font-weight: 600;
  }

  & > *:not(:last-child) {
    margin-right: 2rem;
  }
`;

export const DrawerContent = styled.div`
  width: 70vw;
  height: max-content;
  padding: 2rem 1.5rem;
`;

export const SearchBarLogo = styled.div`
  display: flex;
  align-items: center;
  color: #fff;
  img {
    margin-right: 0.3rem;
  }
`;

export const AdAccount = styled.div`
  border: 2px solid #eee;
  padding: 0.2rem 0.2rem 0.2rem 1rem;
  display: flex;
  align-items: center;
  border-radius: 0.5rem;
  backdrop-filter: blur(13.5px);
  border: 1px solid #ffffff4d;
`;

export const AdAccountDetail = styled.div`
  margin-right: 2rem;
  color: #fff;

  h5 {
    font-size: 1rem;
    margin-bottom: 0.2rem;
  }
  p {
    font-size: 0.8rem;
    font-weight: 400;
    color: #ffffff99;
  }
`;

export const LinkSuccessFulModal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 36rem;
  border-radius: 1rem;
  outline: none;
  overflow: hidden;
  background: linear-gradient(97.29deg, #00258f 10.08%, #001963 99.77%);
  border: 1.5px solid rgba(64, 79, 129, 0.58);
  backdrop-filter: blur(16px);

  img {
    width: 100%;
  }
`;

export const LinkSuccessFulModalBottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 1.5rem;
  color: #fff;

  h3 {
    margin-bottom: 0.5rem;
  }
  p {
    color: #ffffff80;
    font-size: 0.9rem;
    font-weight: 300;
  }
`;

export const CustomPopover = styled(Popover)`
  min-height: 36rem;
  /* margin-top: 1rem ; */

  .MuiPopover-paper {
    border-radius: 1rem !important;
    overflow: hidden !important;
  }
`;

export const SelectAdAccountDropdown = styled.div`
  border-radius: 1rem;
  background-color: #012385;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  ul {
    max-height: 25rem;
    width: 28rem;
    list-style: none;
    overflow-y: auto;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;

    &::-webkit-scrollbar {
      width: 0.4rem;
      border-radius: 0.5rem;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 0.5rem;
      background-color: darkgrey;
      /* outline: 1px solid slategrey; */
    }

    .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper {
      border-radius: 1rem !important;
    }

    li {
      min-width: 20rem;
      padding: 0.8rem 1rem;
      border-bottom: 1px solid #324d9b;
      cursor: pointer;
      border-radius: 0.4rem;
      transition: all 0.2s;

      &:hover {
        background-color: rgba(27, 125, 255, 0.2);
      }

      span {
        color: #ffffff80;
      }
    }
  }
`;

export const CustomToolTip = styled.div`
  font-size: 0.9rem;
`;

export const ChangeModal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 25rem;
  border-radius: 1rem;
  outline: none;
  overflow: hidden;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;

  p {
    font-size: 1.2rem;
  }

  /* background: linear-gradient(97.29deg, #00258F 10.08%, #001963 99.77%);
    border: 1.5px solid rgba(64, 79, 129, 0.58);
    backdrop-filter: blur(16px); */
`;

export const DailyReport = styled(motion.div)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  background: linear-gradient(97.29deg, #00258f 10.08%, #001963 99.77%);
  border: 1.5px solid rgba(64, 79, 129, 0.58);
  backdrop-filter: blur(16px);
  border-radius: 1rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  outline: none;

  h2 {
    color: #fff;
    margin-bottom: 0.2rem;
  }

  p {
    color: #ffffff80;

    em {
      color: #fff;
    }
  }
`;

export const DailyReportFlex = styled.div`
  display: flex;
  margin-top: 2.5rem;

  & > *:not(:last-child) {
    border-right: 1px solid #ffffff80;
  }
`;

export const DailyReportList = styled.div`
  padding: 0 2rem;

  & > *:not(:last-child) {
    margin-bottom: 2rem;
  }
`;

export const StatCard = styled.div`
  display: flex;
  align-items: center;

  p {
    color: #ffffff99;
    font-weight: 300;
  }

  h4 {
    font-size: 1.4rem;
    color: #fff;
    margin-top: 0.2rem;
  }

  span {
    font-size: 1rem;
  }
`;

export const ImgWrap = styled.div`
  background-color: #e4f9ff;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.5rem;
  height: 3.5rem;
  margin-right: 0.7rem;
`;

export const ModalCloseBtn = styled(motion.div)`
  position: absolute;
  top: 0.7rem;
  right: 0.7rem;
`;

export const LinkSentModal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 36rem;
  border-radius: 1.3rem;
  overflow: hidden;
  outline: none;
  overflow: hidden;
  background: linear-gradient(97.29deg, #00258f 10.08%, #001963 99.77%);
  border: 1.5px solid rgba(64, 79, 129, 0.58);
  backdrop-filter: blur(16px);

  img {
    width: 100%;
  }
`;

export const LinkSentModalBottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 1.5rem;
  color: #fff;

  h3 {
    margin-bottom: 0.5rem;
  }
  p {
    color: #ffffff80;
    font-size: 0.9rem;
    font-weight: 300;
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`;

export const WalletContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  border: 1.5px solid rgba(15, 110, 255, 1);
  border-radius: 10px;
  height: 54px;
  min-width: 226px;
  width: max-content;
  padding: 0 15px;

  > a > img {
    height: 22px;
    width: 22px;
  }

  > div > p {
    font-size: 16px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.7);
    position: relative;
    top: 3px;
  }

  > div > h6 {
    font-weight: 700;
    font-size: 18px;
    position: relative;
    top: 2px;
    color: rgba(255, 255, 255, 1);
  }
`;

export const WalletBalanceAddText = styled.p`
  margin-top: 0.2rem;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0.6)
  );
  -webkit-background-clip: text;
`;

export const BellImgContainer = styled.div`
  display: flex;
  position: relative;
  margin-top: 1.2rem;

  > img {
    width: 17px;
    height: 20px;
  }

  > span::before {
    position: absolute;
    content: "";
    top: -2px;
    right: -1px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: red;
    border: 1px solid blue;
  }
`;

export const NotificationModal = styled(Modal)``;

export const ModalBox = styled(Box)`
  position: absolute;
  top: 70px;
  right: 20px;
  height: 422px;
  width: 533px;
  background: linear-gradient(
    to right,
    rgba(17, 50, 148, 1),
    rgba(0, 28, 108, 1)
  );
  color: #fff;
  border-radius: 15px;
  padding: 1.5rem;
  outline: none;

  > hr {
    margin: 1rem 0;
  }

  > p {
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    color: white;
  }
`;

export const ModalTopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > h3 {
    color: white;
    font-size: 24px;
    font-weight: 600;
  }

  > div {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  > div > span {
    border: 1px solid rgba(0, 148, 255, 1);
    width: 75px;
    height: 38px;
    border-radius: 6.3px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
  }

  > div > button {
    border: none;
    background: none;
    outline: none;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    color: white;
  }

  > div > button:hover {
    text-decoration: underline;
  }
`;

export const Notifications = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const StatusTypography = styled(Typography)<{ invoiceStatus: string }>`
  color: ${(props) =>
    props.invoiceStatus === "Pending"
      ? "rgba(237, 192, 31, 1)"
      : props.invoiceStatus === "Success"
      ? "rgba(42, 217, 141, 1)"
      : "rgba(223, 36, 36, 1)"};
`;

export const UpgradePlanContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    to right,
    rgba(0, 82, 241, 1),
    rgba(0, 117, 255, 1)
  );
  height: 53px;
  min-width: 200px;
  width: max-content;
  padding: 0 20px;
  border-radius: 12px;
  color: white;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
`;

export const TrialExpContainer = styled.div<{ disableBtn?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid rgba(0, 33, 128, 1);
  background: linear-gradient(
    to right,
    rgba(17, 50, 148, 1),
    rgba(0, 28, 108, 1)
  );
  height: 89px;
  width: 98%;
  margin: 0.2rem auto 2rem auto;
  border-radius: 15px;
  padding: 0 30px;

  > div {
    display: flex;
    align-items: center;
    gap: 2rem;
  }

  > div > div {
    > h2 {
      font-size: 18px;
      font-weight: 500;
      color: rgba(255, 255, 255, 1);
      line-height: 19.49px;
      margin: 4px 0;
    }
    > h3 {
      font-size: 16px;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.5);
      line-height: 19.49px;
      margin: 4px 0;
    }
  }

  > a,
  > button {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(
      to right,
      rgba(0, 82, 241, 1),
      rgba(0, 117, 255, 1)
    );
    height: 53px;
    width: 190px;
    border-radius: 12px;
    color: white;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    border: none;
    outline: none;
    gap: 0.2rem;
    opacity: ${(props) => (props.disableBtn ? "60%" : "100%")};
  }
`;

export const ReAuthFbContainer = styled.div<{ disableBtn?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid rgba(0, 33, 128, 1);
  background: linear-gradient(
    to right,
    rgba(17, 50, 148, 1),
    rgba(0, 28, 108, 1)
  );
  height: 89px;
  width: 98%;
  margin: 0.2rem auto 2rem auto;
  border-radius: 15px;
  padding: 0 30px;

  > div {
    display: flex;
    align-items: center;
    gap: 2rem;
  }

  > div:nth-child(1) {
    > img {
      height: 100%;
      width: 100px;
      border-radius: 4px;
    }
  }

  > div > div {
    > h2 {
      font-size: 18px;
      font-weight: 500;
      color: rgba(255, 255, 255, 1);
      line-height: 19.49px;
      margin: 4px 0;
    }
    > h3 {
      font-size: 16px;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.5);
      line-height: 19.49px;
      margin: 4px 0;
    }
  }

  > a,
  > button {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(
      to right,
      rgba(0, 82, 241, 1),
      rgba(0, 117, 255, 1)
    );
    height: 53px;
    width: 300px;
    border-radius: 12px;
    color: white;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    border: none;
    outline: none;
    gap: 0.5rem;
    padding: 0 10px;
    opacity: ${(props) => (props.disableBtn ? "60%" : "100%")};
  }
`;

export const CreateAdAccountModal = styled(Modal)``;

export const CreateAdAccountModalBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  > h4 {
    font-size: 24px;
    font-weight: 700;
    line-height: 19.49px;
    color: rgba(255, 255, 255, 1);
    text-align: center;
    margin-bottom: 1rem;
  }

  > p {
    font-size: 16px;
    font-weight: 400;
    line-height: 32px;
    color: rgba(255, 255, 255, 0.5);
    text-align: center;
    margin-bottom: 2rem;
  }

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    outline: none;
    width: 548px;
    height: 400px;
    border: 1px solid rgba(15, 110, 255, 1);
    background-color: rgba(0, 28, 108, 1);
    color: #000;
    border-radius: 15px;
    padding: 1.5rem;

    > h4 {
      font-size: 20px;
      font-weight: 600;
      line-height: 19.49px;
      color: rgba(255, 255, 255, 1);
      margin-top: 1rem;
    }
    > p {
      font-size: 14px;
      font-weight: 400;
      line-height: 32px;
      color: rgba(255, 255, 255, 0.5);
    }
    > img {
      margin: 1rem 0 2rem 0;
    }
    > button {
      background: linear-gradient(
        to left,
        rgba(0, 102, 255, 1),
        rgba(0, 148, 255, 1)
      );
      height: 50px;
      width: 240px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid rgba(15, 110, 255, 1);
      border-radius: 10px;
      outline: none;
      font-size: 16px;
      font-weight: 500;
      line-height: 18px;
      color: rgba(255, 255, 255, 1);
      cursor: pointer;
    }
  }
`;

export const CreateAdAccountMailModal = styled(Modal)``;
export const CreateAdAccountMailModalBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  outline: none;
  width: 568px;
  height: 367px;
  border: none;
  background: linear-gradient(
    to right,
    rgba(0, 37, 143, 1),
    rgba(0, 25, 99, 1)
  );
  color: #fff;
  border-radius: 20px;
  padding: 1.5rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > img {
    height: 252px;
    width: 252px;
  }

  > p {
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    color: white;
  }
`;

export const AdAccountAccessModal = styled(Modal)``;
export const AdAccountAccessModalBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  outline: none;
  width: 400px;
  height: 412px;
  border: 1px solid rgba(15, 110, 255, 1);
  background: linear-gradient(
    to right,
    rgba(0, 37, 143, 1),
    rgba(0, 25, 99, 1)
  );
  color: #fff;
  border-radius: 20px;
  padding: 1.5rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;

  > h5 {
    font-size: 20px;
    font-weight: 600;
    line-height: 19.49px;
    color: rgba(255, 255, 255, 1);
  }

  > img {
  }

  > button {
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    color: rgba(255, 255, 255, 1);
    height: 50px;
    width: 240px;
    border-radius: 10px;
    background: linear-gradient(
      to left,
      rgba(0, 102, 255, 1),
      rgba(0, 148, 255, 1)
    );
    border: 1px solid rgba(15, 110, 255, 1);
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  > p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: rgba(160, 255, 144, 0.73);
    text-decoration: underline;
    text-align: center;
  }
`;

export const AdAccountActivateModal = styled(Modal)``;
export const AdAccountActivateModalBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  outline: none;
  width: 400px;
  height: 412px;
  border: 1px solid rgba(15, 110, 255, 1);
  background: linear-gradient(
    to right,
    rgba(0, 37, 143, 1),
    rgba(0, 25, 99, 1)
  );
  color: #fff;
  border-radius: 20px;
  padding: 1.5rem 54px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;

  > hr {
    border: none;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    width: 100%;
  }

  > p:nth-child(2) {
    font-size: 600;
    font-size: 20px;
    line-height: 19.49px;
    color: rgba(117, 255, 169, 1);
  }

  > p:nth-child(3) {
    font-size: 400;
    font-size: 14px;
    line-height: 32px;
    color: rgba(255, 255, 255, 1);
  }

  > p:nth-child(5) {
    font-size: 400;
    font-size: 14px;
    line-height: 23px;
    color: rgba(255, 255, 255, 1);
    text-align: center;
    span {
      font-weight: 700;
    }
  }
`;
