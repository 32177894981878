import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import UNIVERSAL from "src/config/config";
import { ResponseSuccess } from "src/interfaces/api";
import { createFetchBaseQueryWithReauthentication } from "src/libs/api";

export type invoiceDetails = {
  ad_account: string;
  euid: number;
};

export const appSumoApi = createApi({
  reducerPath: "appSumoApi",
  baseQuery: createFetchBaseQueryWithReauthentication({
    baseUrl: UNIVERSAL.BASEURL + "/api/v1",
  }),

  endpoints: (builder) => ({
    validateAppsumoAuthCode: builder.query<any, { code: string }>({
      query: (params) => ({
        url: `/appsumo/validateAuthCode`,
        method: "GET",
        params,
      }),
      transformResponse: (response: ResponseSuccess<any>) => {
        return response.data;
      },
    }),
  }),
});

export const { useValidateAppsumoAuthCodeQuery } = appSumoApi;
