import { useState, useEffect, useMemo } from "react";
import "react-phone-input-2/lib/style.css";
import {
  AdAccount,
  AdAccountDetail,
  AdAccountFlex,
  Avatar,
  AvatarDetail,
  CompanyDetail,
  CompanyDetailWrapper,
  ConnectBar,
  ConnectFlex,
  ConnectIcon,
  ConnectIconWrapper,
  CustomPopover,
  Dropdown,
  Form,
  GetStarted,
  GetStartedWrapper,
  Input,
  InputLabel,
  LinkFb,
  LinkFbBottom,
  LinkFbBottomLeft,
  LinkFBPagebtn,
  LinkFbWrapper,
  LinkSuccessFulModal,
  LinkSuccessFulModalBottom,
  ListItem,
  Logo,
  Main,
  PageDetail,
  PageList,
  SearchInput,
  SelectAdAccountBtns,
  SelectPageWrapper,
  Topbar,
  Wrapper,
} from "./Onboarding.styles";
import logo from "src/assets/zocketLogo.png";
import avatar from "src/assets/avatar.png";
import companyDetailIcon from "src/assets/companyDetail.svg";
import fbConnectInactive from "src/assets/fbConnectInactive.svg";
import linkFbBg from "src/assets/linkFbBg.svg";
import linkFbIcon from "src/assets/linkFbIcon.svg";
import permissionError from "src/assets/permissionError.png";
import {
  TextField,
  Button,
  Divider,
  MenuItem,
  Modal,
  InputAdornment,
  Alert,
  Popover,
  CircularProgress,
} from "@mui/material";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import {
  addCompanyDetail,
  setCompanyDetails,
} from "src/slices/addCompanyDetail/adCompanyDetailSlice";
import { useAppSelector } from "src/hooks/useAppSelector";
import {
  fetchUserProfile,
  saveUserAccount,
  setFbDetails,
} from "src/slices/user/userSlice";
import { useNavigate } from "react-router-dom";

import SearchIcon from "@mui/icons-material/Search";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import { AnimatePresence } from "framer-motion";
import ZocketLogo from "src/components/zocketLogo/ZocketLogo";
import PhoneInput from "react-phone-input-2";
import { authKey } from "src/constants/storage";
import { reauthenticateUser } from "src/slices/authentication/authentication";

const OnBoarding = () => {
  const { user, loading: saveUserAccountLoading } = useAppSelector(
    (state) => state.user
  );
  const [step, setStep] = useState<number>(1);
  const [website, setWebsite] = useState<string>("");
  const [enteredPhnNumber, setEnteredPhnNumber] = useState("");
  const [countryDialCode, setCountryDialCode] = useState("");
  const [formError, setFormError] = useState<string>("");
  const [allPages, setAllPages] = useState<any[]>([]);
  const [pageSearchTerm, setPageSearchTerm] = useState("");
  const [selectPageModal, setSelectPageModal] = useState(false);
  const [selectedPage, setSelectedPage] = useState<any>("");
  const [permissionModal, setPermissionModal] = useState<boolean>(false);
  const [logoutDropDown, setLogoutDropDown] = useState(false);
  const [logoutDropDownAnchor, setLogoutDropDownAnchor] = useState<any>(null);
  const [mobile, setMobile] = useState("");

  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const authentication = useAppSelector(
    (state) => state.authentication.security
  );

  const fbDetail = useAppSelector((state) => state.user.fb);
  const { loading: addCompanyDetailsLoading, companyDetail } = useAppSelector(
    (state) => state.addCompanydetail
  );

  const userLocation = useAppSelector(
    (state) => state.userLocation.userLocation
  );

  const pageSearchList = useMemo(() => {
    if (pageSearchTerm === "") {
      return allPages;
    }
    return allPages.filter((item) => {
      return item.name.toLowerCase().includes(pageSearchTerm.toLowerCase());
    });
  }, [pageSearchTerm, allPages]);

  useEffect(() => {
    if (!user) {
      const authkey = localStorage.getItem(authKey);
      if (authkey) {
        dispatch(fetchUserProfile());
      } else {
        navigate("/");
      }
    }
    if (user) {
      if (user?.data?.fb_access_token) {
        navigate("/dashboard");
      }
    }
  }, [user]);

  useEffect(() => {
    if (user?.data?.onboarding === true && !user?.data?.fb_access_token) {
      setStep(2);
    } else {
      setWebsite(companyDetail.website);
      setEnteredPhnNumber(companyDetail.contact);
      setCountryDialCode(companyDetail.countryDailCode);
    }
  }, [user]);

  const get_account_pages = (userID: string, url: string, pages: any[]) => {
    /* global FB */

    let tmp = pages;
    FB.api(url, (res: any) => {
      if (res.data.length !== 0) {
        tmp = [...pages, ...res.data];
        const new_url = `/${userID}/accounts?fields=name,id,access_token,picture,business&after=${res.paging.cursors.after}`;
        get_account_pages(userID, new_url, tmp);
      } else {
        setAllPages(tmp);
      }
    });
    return;
  };

  const check_permissions = (data: any): boolean => {
    let count = 0;
    const permissions = [
      "pages_read_engagement",
      "public_profile",
      "pages_show_list",
      "business_management",
      "pages_manage_metadata",
      "ads_management",
      "email",
      "catalog_management",
    ];

    data.map((item: any) => {
      if (permissions.includes(item.permission) && item.status === "granted") {
        count = count + 1;
      }
    });
    return count === permissions.length ? true : false;
  };

  const do_fb_login = () => {
    /* global FB */
    const _window = window as any;
    const _FB = _window.FB;

    _FB.login(
      function (response: any) {
        if (response.authResponse) {
          _FB.api(`/me/permissions`, "GET", {}, function (res: any) {
            const allPermissions = check_permissions(res.data);

            if (allPermissions) {
              get_account_pages(
                response.authResponse.userID,
                `/${response.authResponse.userID}/accounts?fields=name,id,picture,access_token,business`,
                []
              );

              _FB.api(
                `/${response.authResponse.userID}?fields=name,id,picture,email`,
                function (res: any) {
                  if (res) {
                    dispatch(
                      setFbDetails({ ...response.authResponse, ...res })
                    );
                  }
                }
              );

              _FB.api(
                `/debug_token?input_token=${response.authResponse.accessToken}`,
                function (response: any) {
                  if (response && !response.error) {
                    /* handle the result */

                    console.log(response, "DEBUG RESPONSE");
                  }
                }
              );
              setSelectPageModal(true);
            } else {
              // SHOW PERMISSIONS NOT GRANTED
              // FB.logout();
              setPermissionModal(true);
            }
          });
        } else {
          console.log("User cancelled login or did not fully authorize.");
        }
      },
      {
        scope:
          "pages_read_engagement, public_profile, pages_show_list, business_management, pages_manage_metadata, ads_management, email, catalog_management",
        auth_type: "rerequest",
      }
    );
  };

  const add_detail = () => {
    if (!website || !enteredPhnNumber || !countryDialCode) {
      setFormError("Please provide all the details");
    } else {
      dispatch(
        addCompanyDetail({
          website: website,
          contact: mobile,
          euid: String(user?.data?.euid),
          countryDailCode: countryDialCode,
        })
      )
        .unwrap()
        .then((res: any) => {
          setStep(2);
          setCompanyDetails({
            website: website,
            contact: mobile,
            euid: String(user?.data?.euid),
            countryDailCode: countryDialCode,
          });
        });
    }
  };

  const handle_close_page_select_modal = () => {
    if (selectedPage === "") return;
    dispatch(setFbDetails({ ...fbDetail, selectedFbPage: selectedPage }));
    dispatch(
      saveUserAccount({
        fbAccessToken: fbDetail.accessToken,
        euid: user?.data?.euid,
        fbId: fbDetail.id,
        pageId: selectedPage.id,
        pageToken: selectedPage.access_token,
        page_name: selectedPage.name,
        page_profile_picture_url: selectedPage.picture.data.url,
        user_name: fbDetail.name,
        user_profile_picture_url: fbDetail.picture.data.url,
        email: fbDetail.email ? fbDetail.email : "",
      })
    )
      .unwrap()
      .then((res) => {
        dispatch(fetchUserProfile())
          .unwrap()
          .then(() => {
            setStep(3);
            setSelectPageModal(false);
          });
      });
  };

  const close_profile_dropdown = () => {
    setLogoutDropDown(() => false);
    setLogoutDropDownAnchor(() => null);
    console.log(logoutDropDown, logoutDropDownAnchor, "Hello");
  };

  const handle_logout = () => {
    /* global FB */
    const _window = window as any;
    const _FB = _window.FB;

    _FB.logout();
    localStorage.removeItem(authKey);
    dispatch(reauthenticateUser());
    navigate("/");
  };

  function handleEnteredPhoneNumber(phone: any, data: any) {
    setEnteredPhnNumber(phone);
    setCountryDialCode(data.dialCode);
    let dialCodeLength = data.dialCode.length;
    let number = phone.slice(dialCodeLength);
    setMobile(number);
  }

  return (
    <Wrapper>
      <Topbar>
        <Logo>
          {/* <img src={logo} /> */}
          {/* <ZocketLogo width="3.6rem" initialPos="90" />
          <h2>Zocket</h2> */}
          <img
            src="https://drbgg29kvmub6.cloudfront.net/assets/animationFiles/companylogo.svg"
            alt=""
          />
        </Logo>
        {/* onClick={(e) => { setLogoutDropDownAnchor(e.currentTarget); setLogoutDropDown(true) }} */}
        <Avatar
          onClick={(e) => {
            setLogoutDropDownAnchor(e.currentTarget);
            setLogoutDropDown(!logoutDropDown);
          }}
        >
          <img src={avatar} />
          <AvatarDetail>
            <h3>{user?.data?.business_name}</h3>
            <p>{user?.data?.email}</p>
          </AvatarDetail>
          <ExpandMoreIcon />
          <CustomPopover
            sx={{ borderRadius: 20 }}
            open={logoutDropDown}
            onClose={() => close_profile_dropdown()}
            anchorEl={logoutDropDownAnchor}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
          >
            <Dropdown>
              <Button onClick={() => handle_logout()} variant="outlined">
                Log out
              </Button>
            </Dropdown>
          </CustomPopover>
        </Avatar>
      </Topbar>
      <Main>
        <ConnectFlex>
          <ConnectIconWrapper>
            <ConnectIcon>
              <img src={companyDetailIcon} />
              <ConnectBar />
            </ConnectIcon>
            <p>Company details</p>
          </ConnectIconWrapper>

          <ConnectIconWrapper inactive={step === 1}>
            <ConnectIcon inactive={step === 1}>
              <img src={fbConnectInactive} />
            </ConnectIcon>
            <p>Link facebook</p>
          </ConnectIconWrapper>
        </ConnectFlex>
        <AnimatePresence>
          {user && step === 1 && (
            <CompanyDetailWrapper
              initial={{ x: 100, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: -100, opacity: 0 }}
              // transition={{ ease: "easeInOut", duration: 0.5, delay: 0.1 }}
            >
              <CompanyDetail>
                <h3>Add your company details</h3>
                <p>Please fill your company details</p>
                {formError && (
                  <Alert
                    sx={{ marginTop: "1rem", width: "100%" }}
                    severity="error"
                  >
                    {formError}
                  </Alert>
                )}
                <Form>
                  <Input>
                    <InputLabel>Phone Number</InputLabel>
                    <PhoneInput
                      country={
                        userLocation
                          ? userLocation.toString().toLowerCase()
                          : "IN"
                      }
                      searchPlaceholder="Enter your phone number"
                      containerClass="signupPhoneContainer"
                      inputClass="signupSearchOnBoarding"
                      dropdownClass="signupDropdown"
                      value={enteredPhnNumber}
                      countryCodeEditable={false}
                      placeholder="Your Phone Number"
                      onChange={(phone, data) =>
                        handleEnteredPhoneNumber(phone, data)
                      }
                    />
                  </Input>
                  <Input>
                    <InputLabel>Website</InputLabel>
                    <TextField
                      value={website}
                      onChange={(e) => setWebsite(e.target.value)}
                      placeholder="Enter your website"
                    />
                  </Input>
                </Form>
              </CompanyDetail>
              <Button
                onClick={() => add_detail()}
                sx={{ alignSelf: "flex-end", minWidth: "10rem" }}
                variant="contained"
              >
                {addCompanyDetailsLoading ? (
                  <CircularProgress
                    style={{ width: "1.6rem", height: "1.6rem" }}
                  />
                ) : (
                  "Submit Details"
                )}
              </Button>
            </CompanyDetailWrapper>
          )}
          {user && step === 2 && (
            <LinkFbWrapper
              initial={{ x: 100, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: -100, opacity: 0 }}
            >
              <LinkFb>
                <img src={linkFbBg} />
                <LinkFbBottom>
                  <LinkFbBottomLeft>
                    <h3>Link your facebook page</h3>
                    <p>Please link in your FB page to start running your ad</p>
                  </LinkFbBottomLeft>
                  <LinkFBPagebtn onClick={() => do_fb_login()}>
                    <img src={linkFbIcon} />
                    <p>Link FB page</p>
                  </LinkFBPagebtn>
                </LinkFbBottom>
              </LinkFb>
              {/* <Button sx={{ alignSelf: "flex-end" }} variant="contained">Submit Details</Button> */}
            </LinkFbWrapper>
          )}
          {user && step === 3 && (
            <GetStartedWrapper
              initial={{ x: 100, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: -100, opacity: 0 }}
            >
              <GetStarted>
                <h3>Your linked Facebook page</h3>
                <Divider />
                <AdAccountFlex>
                  <AdAccount>
                    <img src={fbDetail?.selectedFbPage?.picture?.data?.url} />
                    <AdAccountDetail>
                      <h3>{fbDetail?.selectedFbPage?.name}</h3>
                      <p>{fbDetail?.selectedFbPage?.id}</p>
                    </AdAccountDetail>
                  </AdAccount>
                  <Button
                    onClick={() => setSelectPageModal(true)}
                    variant="outlined"
                    startIcon={<SwapHorizIcon />}
                  >
                    Switch
                  </Button>
                </AdAccountFlex>
              </GetStarted>
              <Button
                onClick={() => navigate("/dashboard")}
                sx={{ alignSelf: "flex-end" }}
                variant="contained"
              >
                Get started
              </Button>
            </GetStartedWrapper>
          )}
        </AnimatePresence>
      </Main>
      <Modal open={selectPageModal} onClose={() => setSelectPageModal(false)}>
        <SelectPageWrapper>
          <p>Your pages</p>
          <Divider />

          <SearchInput>
            <TextField
              size="small"
              fullWidth
              placeholder="Search with page name"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              value={pageSearchTerm}
              onChange={(e) => setPageSearchTerm(e.target.value)}
            />
          </SearchInput>
          <PageList>
            {allPages?.length === 0 && <p>No pages present...</p>}
            {pageSearchList?.map((item) => {
              return (
                <>
                  <ListItem
                    onClick={() => {
                      setSelectedPage(item);
                    }}
                    key={item?.account_id}
                    selected={selectedPage.id === item?.id}
                  >
                    <PageDetail>
                      <img src={item.picture.data.url} />
                      <div>
                        <h4>{item?.name}</h4>
                        <p>{item?.id}</p>
                      </div>
                    </PageDetail>

                    <Button
                      sx={{ paddingTop: "0.5rem", paddingBottom: "0.5rem" }}
                      startIcon={<DeleteIcon />}
                      size="small"
                      variant="outlined"
                      color="warning"
                    >
                      Remove
                    </Button>
                  </ListItem>
                  <Divider />
                </>
              );
            })}
          </PageList>

          <SelectAdAccountBtns>
            <Button variant="outlined">Add more</Button>
            <Button
              startIcon={saveUserAccountLoading ? null : <CheckCircleIcon />}
              sx={{
                marginLeft: "0.6rem",
                width: "7.5rem",
              }}
              onClick={() => handle_close_page_select_modal()}
              variant="contained"
            >
              {saveUserAccountLoading ? (
                <CircularProgress
                  style={{ width: "1.6rem", height: "1.6rem" }}
                />
              ) : (
                "Done"
              )}
            </Button>
          </SelectAdAccountBtns>
        </SelectPageWrapper>
      </Modal>
      <Modal open={permissionModal} onClose={() => setPermissionModal(false)}>
        <LinkSuccessFulModal>
          <img src={permissionError} />
          <LinkSuccessFulModalBottom>
            <h3>Please grant permissions</h3>
            <p>
              you have not granted all the permissions pls grant all the
              permission
            </p>
          </LinkSuccessFulModalBottom>
        </LinkSuccessFulModal>
      </Modal>
    </Wrapper>
  );
};

export default OnBoarding;
