import { Mutex } from "async-mutex";
import { createInstance } from "src/libs/utils";

const UNIVERSAL = {
  // BASEURL: "https://x.zocket.com/test",
  // BASEURL2: "https://x.zocket.com/fbwebapi",
  BASEURL: "https://x.zocket.com/ad360",
  BASEURL2: "https://x.zocket.com/prod/fbwebapi",

  LOGIN_EMAIL: "user@zocket.com",
  LOGIN_PASSWORD: "user1234",

  GOOGLE_LOGIN_CLIENT_ID:
    "863482939242-c2em34bjsaktpbsnbu4gtgvao55dhbpg.apps.googleusercontent.com",

  // RAZORPAY_KEY_ID: "rzp_test_vHE4cfpoXYi5KE",
  // RAZORPAY_KEY_SECRET: "cHC2fvf1Jo93bTvguMPBXJV9",

  RAZORPAY_KEY_ID: "rzp_live_MqPPrGP3sDunDo",
  RAZORPAY_KEY_SECRET: "Dlb1G2rRoGBuY6pCwvfCzWIY",

  RECAPTCHA_KEY: "6LeU-moqAAAAAAEKcTVS-03LJ5hs-AR8EdKO2K0f",
};

export const apiAsyncMutex = createInstance(Mutex);

export default UNIVERSAL;
