import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import UNIVERSAL from "../../config/config";
import { api } from "src/libs/api";
import { reauthenticateUser } from "../authentication/authentication";

type InitialState = {
  data: any;
  loading: boolean;
  error: any;
};

type GetTransactionsPayload = {
  euid: number;
};

const initialState: InitialState = {
  data: "",
  loading: true,
  error: "",
};

export const getTransactions = createAsyncThunk(
  "/reports/getTransactions",
  (data: GetTransactionsPayload, { rejectWithValue }) => {
    return api(`${UNIVERSAL.BASEURL}/api/v1/wallet/get_payments`, {
      method: "POST",
      body: JSON.stringify({
        euid: data.euid,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (!res.success) return rejectWithValue(res);
        if (res.success) return res;
      });
  }
);

const walletDetails = createSlice({
  name: "walletDetails",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(reauthenticateUser, () => initialState);
    builder.addCase(getTransactions.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTransactions.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
      state.error = "";
    });
    builder.addCase(getTransactions.rejected, (state, action) => {
      state.loading = false;
      state.data = "";
      state.error = action?.payload;
    });
  },
});

export default walletDetails.reducer;
