import { useEffect, useState } from "react";
import * as Yup from "yup";

import store from "../config/store";
import {
  reauthenticateUser,
  updateOAuth2Tokens,
} from "src/slices/authentication/authentication";
import { authKey } from "src/constants/storage";
import { fetchUserProfile } from "src/slices/user/userSlice";

const SecuritySchema = Yup.object().shape({
  access_token: Yup.string().required(),
  refresh_token: Yup.string().required(),
  access_token_expiry: Yup.number().required(),
  refresh_token_expiry: Yup.number().required(),
});

export function useAuthentication() {
  const [isInitialized, setInitialized] = useState(false);

  useEffect(() => {
    if (isInitialized) return;

    (async () => {
      const auth = localStorage.getItem(authKey);

      if (!auth) {
        store.dispatch(reauthenticateUser());
        setInitialized(true);
      } else {
        try {
          const parsed = JSON.parse(auth);
          const security = await SecuritySchema.validate(parsed);
          store.dispatch(updateOAuth2Tokens(security));
          store.dispatch(fetchUserProfile());
          // fetch the user profile and save it to the redux store
        } catch (error) {
          store.dispatch(reauthenticateUser());
        } finally {
          setInitialized(true);
        }
      }
    })();
  }, []); // The user authentication should only run once, as the params and state are not expected to change

  return isInitialized;
}
