import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { Provider } from "react-redux";
import store from "./config/store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { SnackbarProvider } from "notistack";
import { AuthenticationProvider } from "./context/AuthenticationProvider";

const anchorOrigin = { horizontal: "right", vertical: "top" };

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <AuthenticationProvider>
        <SnackbarProvider anchorOrigin={anchorOrigin}>
          <App />
        </SnackbarProvider>
      </AuthenticationProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// serviceWorkerRegistration.register();
reportWebVitals();
