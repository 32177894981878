import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import UNIVERSAL from "../../config/config";
import { api } from "src/libs/api";
import { reauthenticateUser } from "../authentication/authentication";

type InitialState = {
  userLocation: any;
  currency: any;
  loading: boolean;
  error: any;
};

const initialState: InitialState = {
  userLocation: "",
  currency: "",
  loading: false,
  error: "",
};

export const userLocation = createAsyncThunk("/userLocation", () => {
  return api(`${UNIVERSAL.BASEURL}/api/v1/utils/check_location`, {
    method: "GET",
  })
    .then((response) => response.json())
    .then((res) => {
      return res.data.country_code;
    });
});

const userLocationSlice = createSlice({
  name: "userLocation",
  initialState,
  reducers: {
    setCurrency: (state, action) => {
      state.currency = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(reauthenticateUser, () => initialState);
    builder.addCase(userLocation.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(userLocation.fulfilled, (state, action) => {
      state.loading = false;
      state.userLocation = action.payload;
      state.error = "";
    });
    builder.addCase(userLocation.rejected, (state, action) => {
      state.loading = false;
      state.userLocation = "";
      state.error = action.error.message;
    });
  },
});

export default userLocationSlice.reducer;
