import Login from "./pages/login/Login";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Layout from "./layout/Layout";
import { ThemeProvider, createTheme } from "@mui/material";
import OnBoarding from "./pages/onboarding/OnBoarding";
import ResetPassword from "./pages/resetPassword/ResetPassword";
import PaymentRedirect from "./pages/paymentRedirect/PaymentRedirect";
import { useAppDispatch } from "./hooks/useAppDispatch";
import { useEffect } from "react";
import { userLocation } from "./slices/location/userlocationSlice";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import UNIVERSAL from "./config/config";

const theme = createTheme({
  palette: {
    text: {
      primary: "#FFFFFF",
      secondary: "#B0B0B0",
    },
    mode: "dark",
  },
  typography: {
    fontFamily: "Montserrat, sans-serif",
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        size: "large",
      },
      styleOverrides: {
        root: {
          textTransform: "initial",
          borderRadius: 10,
        },
        containedPrimary: {
          color: "#FFFFFF",
          background: `linear-gradient(270deg, #0066FF 0%, #0094FF 100%);`,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        sizeLarge: {
          borderRadius: 12,
          "& .MuiTouchRipple-root .MuiTouchRipple-child": {
            borderRadius: 12,
          },
        },
        sizeMedium: {
          borderRadius: 12,
          "& .MuiTouchRipple-root .MuiTouchRipple-child": {
            borderRadius: 12,
          },
        },
        sizeSmall: {
          borderRadius: 8,
          "& .MuiTouchRipple-root .MuiTouchRipple-child": {
            borderRadius: 8,
          },
        },
      },
    },
    MuiContainer: {
      defaultProps: {
        maxWidth: false,
      },
    },

    MuiCssBaseline: {
      styleOverrides: {
        body: {
          background:
            "linear-gradient(250deg, #0C0C1F 5%, #0A0E2B 15%, #06164E 35%, #002284 65%)",
        },
      },
    },
  },
});

function App() {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(userLocation());
  }, []);
  // test fix
  return (
    <GoogleReCaptchaProvider reCaptchaKey={UNIVERSAL.RECAPTCHA_KEY}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/link_account" element={<Layout />} />
            <Route path="/onboarding" element={<OnBoarding />} />
            <Route path="/dashboard/*" element={<Layout />} />
            <Route path="/live_ad_copies" element={<Layout />} />
            <Route path="/settings" element={<Layout />} />
            <Route path="/wallet" element={<Layout />} />
            <Route path="/transaction" element={<Layout />} />
            {/* <Route path="/set_rules" element={<Layout />} /> */}
            <Route path="/reset_password" element={<ResetPassword />} />
            <Route path="/validate_payment" element={<PaymentRedirect />} />
            <Route path="/campaign" element={<Layout />} />
            <Route path="/subscription" element={<Layout />} />
            <Route path="*" element={<Navigate to="/dashboard" />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </GoogleReCaptchaProvider>
  );
}

export default App;
