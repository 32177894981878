import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import UNIVERSAL from "src/config/config";
import { ResponseSuccess } from "src/interfaces/api";
import { createFetchBaseQueryWithReauthentication } from "src/libs/api";

export type invoiceDetails = {
  ad_account: string;
  euid: number;
};

export const invoiceApi = createApi({
  reducerPath: "invoiceApi",
  baseQuery: createFetchBaseQueryWithReauthentication({
    baseUrl: UNIVERSAL.BASEURL,
  }),
  tagTypes: ["invoice"],
  endpoints: (builder) => ({
    // eslint-disable-next-line
    getBankTransferDetails: builder.query<any, invoiceDetails>({
      query: (body) => ({
        url: `/api/v1/wallet/get_bank_transfer_details`,
        method: "POST",
        body,
      }),
      // eslint-disable-next-line
      transformResponse: (response: ResponseSuccess<any>) => {
        return response.data;
      },
    }),
  }),
});

export const { useGetBankTransferDetailsQuery } = invoiceApi;
