import {
  PayloadAction,
  createAction,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import { authenticationApi } from "src/api/authentication";
import UNIVERSAL from "src/config/config";
import { authKey } from "src/constants/storage";
import {
  SecurityResponse,
  UserProfileResponse,
} from "src/types/authentication";

export type AuthenticationState = {
  is_user_exists: boolean | null;
  user: UserProfileResponse | null;
  security: SecurityResponse | null;
  reauthenticate: false;
};

const initialState: AuthenticationState = {
  is_user_exists: null,
  user: null,
  security: null,
  reauthenticate: false,
};

export const loginWithGoogle = createAsyncThunk(
  "/authentication/loginWithGoogle",
  (token: string, { rejectWithValue }) => {
    return fetch(`${UNIVERSAL.BASEURL}/api/v1/auth/google/login`, {
      method: "POST",
      body: JSON.stringify({
        googleJWT: token,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res?.data?.is_user_exists === false) {
          return rejectWithValue(res);
        } else {
          window.location.replace("/dashboard");
          return res.data;
        }
      });
  }
);

export const signupWithGoogle = createAsyncThunk(
  "/authentication/signupWithGoogle",
  (token: string) => {
    return fetch(`${UNIVERSAL.BASEURL}/api/v1/auth/google/signup`, {
      method: "POST",
      body: JSON.stringify({
        googleJWT: token,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.success) {
          // loginWithGoogle(token);
        }
      });
  }
);

export const reauthenticateUser = createAction("reauthenticateUser");

export const authenticationSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    updateOAuth2Tokens: (state, action: PayloadAction<SecurityResponse>) => {
      state.security = action.payload;
      localStorage.setItem(authKey, JSON.stringify(action.payload));
    },
  },
  extraReducers: (builder) => {
    builder.addCase(reauthenticateUser, () => initialState);
    builder.addCase(loginWithGoogle.fulfilled, (state, action) => {
      state.is_user_exists = action.payload.is_user_exists;
      state.user = action.payload.user;
      state.security = {
        access_token: action.payload.access_token,
        access_token_expiry: action.payload.access_token_expiry,
        refresh_token: action.payload.refresh_token,
        refresh_token_expiry: action.payload.refresh_token_expiry,
      };
      localStorage.setItem(
        authKey,
        JSON.stringify({
          access_token: action.payload.access_token,
          access_token_expiry: action.payload.access_token_expiry,
          refresh_token: action.payload.refresh_token,
          refresh_token_expiry: action.payload.refresh_token_expiry,
        })
      );
    });
    builder
      .addMatcher(
        authenticationApi.endpoints.verifyLoginWithEmailOTP.matchFulfilled,
        (state, action) => {
          state.is_user_exists = action.payload.is_user_exists;
          state.user = action.payload.user;
          state.security = {
            access_token: action.payload.access_token,
            access_token_expiry: action.payload.access_token_expiry,
            refresh_token: action.payload.refresh_token,
            refresh_token_expiry: action.payload.refresh_token_expiry,
          };
          localStorage.setItem(
            authKey,
            JSON.stringify({
              access_token: action.payload.access_token,
              access_token_expiry: action.payload.access_token_expiry,
              refresh_token: action.payload.refresh_token,
              refresh_token_expiry: action.payload.refresh_token_expiry,
            })
          );
        }
      )
      .addMatcher(
        authenticationApi.endpoints.verifySignupWithEmailOTP.matchFulfilled,
        (state, action) => {
          state.is_user_exists = action.payload.is_user_exists;
          state.user = action.payload.user;
          state.security = {
            access_token: action.payload.access_token,
            access_token_expiry: action.payload.access_token_expiry,
            refresh_token: action.payload.refresh_token,
            refresh_token_expiry: action.payload.refresh_token_expiry,
          };
          localStorage.setItem(
            authKey,
            JSON.stringify({
              access_token: action.payload.access_token,
              access_token_expiry: action.payload.access_token_expiry,
              refresh_token: action.payload.refresh_token,
              refresh_token_expiry: action.payload.refresh_token_expiry,
            })
          );
        }
      );
  },
});

export const { updateOAuth2Tokens } = authenticationSlice.actions;
