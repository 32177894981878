import { OverageCharges, OverageChargesAppSumo } from "src/interfaces/entity";

export const OverageChargesData: OverageCharges[] = [
  {
    plans: "₹ 8,299",
    spends_upto: "₹ 1,66,000",
    overage: "7.00%",
    usd_plans: "$ 99",
    usd_spends_upto: "$ 2,000",
  },
  {
    plans: "₹ 16,599",
    spends_upto: "₹ 3,73,500",
    overage: "6.00%",
    usd_plans: "$ 199",
    usd_spends_upto: "$ 4,500",
  },
  {
    plans: "₹ 24,899",
    spends_upto: "₹ 8,30,000",
    overage: "4.00%",
    usd_plans: "$ 299",
    usd_spends_upto: "$ 10,000",
  },
  {
    plans: "₹ 41,499",
    spends_upto: "₹ 16,60,000",
    overage: "3.50%",
    usd_plans: "$ 499",
    usd_spends_upto: "$ 20,000",
  },
  {
    plans: "₹ 82,999",
    spends_upto: "₹ 41,50,000",
    overage: "2.00%",
    usd_plans: "$ 999",
    usd_spends_upto: "$ 50,000",
  },
];

export const OverageChargesUSDData: OverageCharges[] = [
  {
    plans: "₹ 16,599",
    spends_upto: "₹ 3,73,500",
    overage: "6.00%",
    usd_plans: "$ 199",
    usd_spends_upto: "$ 4,500",
    amount: 16599,
  },
  {
    plans: "₹ 24,899",
    spends_upto: "₹ 8,30,000",
    overage: "4.00%",
    usd_plans: "$ 299",
    usd_spends_upto: "$ 10,000",
    amount: 24899,
  },
  {
    plans: "₹ 41,499",
    spends_upto: "₹ 16,60,000",
    overage: "3.50%",
    usd_plans: "$ 499",
    usd_spends_upto: "$ 20,000",
    amount: 41499,
  },
  {
    plans: "₹ 82,999",
    spends_upto: "₹ 41,50,000",
    overage: "2.00%",
    usd_plans: "$ 999",
    usd_spends_upto: "$ 50,000",
    amount: 82999,
  },
];

export const OverageChargesAppSumoData: OverageChargesAppSumo[] = [
  {
    plans: "$ 29",
    overage: "4.50%",
  },
  {
    plans: "$ 59",
    overage: "4.00%",
  },
  {
    plans: "$ 99",
    overage: "3.50%",
  },
  {
    plans: "$ 199",
    overage: "3.00%",
  },
  {
    plans: "$ 399",
    overage: "2.00%",
  },
];
