import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import UNIVERSAL from "../../config/config";
import { api } from "src/libs/api";
import { reauthenticateUser } from "../authentication/authentication";

type InitialState = {
  data: any;
  loading: boolean;
  error: any;
};

type GetReportPayload = {
  adAccountId: string;
  adId: string;
  adsetId: string;
  campaignId: string;
  datePreset: string;
  dateStart: string;
  dateStop: string;
  euid: string;
};

const initialState: InitialState = {
  data: "",
  loading: true,
  error: "",
};

export const getReport = createAsyncThunk(
  "/reports/getReport",
  (data: GetReportPayload, { rejectWithValue }) => {
    return api(`${UNIVERSAL.BASEURL}/api/v1/facebook/get_stats`, {
      method: "POST",
      body: JSON.stringify({
        account_id: data.adAccountId,
        ad_id: "",
        adset_id: "",
        campaign_id: "",
        date_preset: data.datePreset,
        date_start: "",
        date_stop: "",
        euid: data.euid,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (!res.success) return rejectWithValue(res);
        if (res.success) return res;
      });
  }
);

const getReports = createSlice({
  name: "reports",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(reauthenticateUser, () => initialState);
    builder.addCase(getReport.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getReport.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
      state.error = "";
    });
    builder.addCase(getReport.rejected, (state, action) => {
      state.loading = false;
      state.data = "";
      state.error = action?.payload;
    });
  },
});

export default getReports.reducer;
