import React from "react";
import {
  AdAccountCardContainer,
  CombineContainer,
  LastContainer,
  MiddleContainer,
  StartContainer,
  WalletBalanceAddText,
  WalletContainer,
} from "./AdAccountCard.styles";
import { FlexContainer } from "src/layout/Layout.styles";
import walletIcon from "../../assets/walletIcon.svg";
import AddWalletIcon from "../../assets/Add Wallet.svg";
import { useGetAdsSpendBalanceQuery } from "src/api/wallet-balance";
import { useAppSelector } from "src/hooks/useAppSelector";
import { fetchSymbolFromCurrency } from "src/libs/utils";

type Props = {
  account: any;
  change_ad_account: any;
  setStepNumber: any;
  euid: any;
};

const AdAccountCard = ({
  account,
  change_ad_account,
  setStepNumber,
  euid,
}: Props) => {
  const {
    data: walletBalance,
    isLoading: isWalletBalanceLoading,
    isSuccess,
  } = useGetAdsSpendBalanceQuery({
    // ad_account: "307452422272679",
    // euid: 626,
    ad_account: account.ad_account_id,
    euid: euid,
  });

  const user = useAppSelector((state) => state.user);

  const handleSelectAdAccount = (selectedAdAccount: any) => {
    if (user.user.data.fb_ad_account === selectedAdAccount.ad_account_id) {
      setStepNumber(2);
    } else {
      change_ad_account(selectedAdAccount);
      if (!user.isAdAccountChanging) {
        setStepNumber(2);
      }
    }
  };

  return (
    <AdAccountCardContainer>
      <CombineContainer>
        {/* <StartContainer>
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/44/BMW.svg/1200px-BMW.svg.png"
            alt="ad-account-img"
          />
        </StartContainer> */}
        <MiddleContainer>
          <h5>{account.ad_account_name}</h5>
          {/* <WalletBalanceAddText>
            Recently Added : 15,000 | 23 Dec 2023
          </WalletBalanceAddText> */}
          <hr />
          <WalletContainer>
            <img src={walletIcon} alt="wallet-icon" />
            <FlexContainer>
              <p>Balance: </p>
              <h6>
                <span>
                  {account.currency
                    ? String(account.currency).toLowerCase() === "inr" ||
                      account.currency === "" ||
                      account.currency === null
                      ? "₹"
                      : fetchSymbolFromCurrency(account.currency)
                    : "₹"}
                </span>
                {isWalletBalanceLoading
                  ? "Loading..."
                  : isSuccess
                  ? Number(walletBalance?.balance).toFixed(2)
                  : "0"}
              </h6>
            </FlexContainer>
          </WalletContainer>
        </MiddleContainer>
      </CombineContainer>
      <LastContainer onClick={() => handleSelectAdAccount(account)}>
        <img src={AddWalletIcon} alt="wallet-icon" />
        Add Money
      </LastContainer>
    </AdAccountCardContainer>
  );
};

export default AdAccountCard;
