import { ChangeEvent, useEffect, useState } from "react";
import CloseCircleIcon from "../../assets/close-circle.svg";
import {
  ACHChargesContainer,
  ACHCreditCharges,
  AmountDetail,
  AmountTotal,
  ChargesContainer,
  CreditCharges,
  CrossIconContainer,
  ModalBodyDetails,
  ModalBodyTop,
  ModalBox,
  ModalBoxBody,
  PaymentDetailsContainer,
  PaymentFormControl,
  PaymentFormControlLabel,
  ProcessingFee,
  SubmitButton,
  WalletBalance,
  WalletBalanceLeft,
  WalletGlobalContainer,
  WalletNote,
  Wrapper,
} from "./WalletDropDownGlobal.styles";
import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Zoom,
} from "@mui/material";
import { useAppSelector } from "src/hooks/useAppSelector";
import lockIcon from "src/assets/lockIcon.svg";
import razorpayIcon from "src/assets/razorpayIcon.svg";
import WalletIcon from "../../assets/WalletBalanceIcon.svg";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CashImage from "../../assets/MoneyImg.svg";
import {
  useCreateAdspendStripeLinkMutation,
  useGetAdsSpendBalanceQuery,
  useGetProcessingFeeQuery,
} from "src/api/wallet-balance";
import { useSnackbar } from "notistack";
import { createStyles } from "src/config/theme";
import { CustomToolTip } from "../adCard/AdCard.styles";
import { fetchSymbolFromCurrency } from "src/libs/utils";

const WalletDropDownGlobal = () => {
  const { enqueueSnackbar } = useSnackbar();
  const user = useAppSelector((state) => state.user.user);
  const [amount, setAmount] = useState("");
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [detailCopied, setDetailCopied] = useState(false);
  const [showPaymentDetailsModal, setShowPaymentDetailsModal] = useState(false);
  // const [showPaymentModeModal, setShowPaymentModeModal] = useState(false);

  const [paymentMode, setPaymentMode] = useState("card");

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPaymentMode(event.target.value);
  };

  const {
    data: walletBalance,
    isLoading: isWalletBalanceLoading,
    isSuccess,
  } = useGetAdsSpendBalanceQuery({
    ad_account: user?.data?.fb_ad_account,
    euid: user?.data?.euid,
  });

  const {
    data,
    isLoading,
    isSuccess: isGetProcessingFeeSuccess,
    isError,
  } = useGetProcessingFeeQuery(
    {
      ad_account: user?.data?.fb_ad_account,
      email: user?.data?.email,
      euid: user?.data?.euid,
      exc_tax: Number(amount.replace(/\,/g, "")),
    },
    { skip: !Number(amount.replace(/\,/g, "")) }
  );

  const [createAdspendStripeLink] = useCreateAdspendStripeLinkMutation();

  let processingFee = isGetProcessingFeeSuccess
    ? parseFloat(String(Number(data.toString().replace(/\,/g, "")))).toFixed(2)
    : "0";

  let totalAmt = Number(amount.replace(/\,/g, "")) + Number(processingFee);

  const AddMoneyThroughStripehandler = async () => {
    setIsBtnDisabled(true);
    if (amount.replace(/\,/g, "")) {
      try {
        await createAdspendStripeLink({
          ad_account: user.data.fb_ad_account,
          email: user.data.email,
          euid: user.data.euid,
          name: user.data.ad_account_name,
          process_fee: String(Number(processingFee)),
          process_percent: "6",
          request_amount: String(Number(amount.replace(/\,/g, ""))),
          tax: "18",
          total_amount: String(totalAmt),
          payment_mode: paymentMode,
        })
          .unwrap()
          .then((response) => {
            if (response && response.length > 0) {
              window.open(response, "_self");
              setIsBtnDisabled(false);
              enqueueSnackbar("Generated Adspend Link Successful", {
                variant: "success",
              });
            } else {
              setShowPaymentDetailsModal(true);
              setIsBtnDisabled(false);
              enqueueSnackbar("Generated Bank Details Successful", {
                variant: "success",
              });
            }
          });
      } catch (e: any) {
        if (e.data.message) {
          enqueueSnackbar(e.data.message, { variant: "error" });
          setIsBtnDisabled(false);
        } else {
          enqueueSnackbar("Something went wrong", { variant: "error" });
          setIsBtnDisabled(false);
        }
      }
    }
  };

  useEffect(() => {
    if (isError) {
      enqueueSnackbar("Subscription has been expired", { variant: "error" });
    }
  }, [isError]);

  return (
    <WalletGlobalContainer>
      <Wrapper>
        <WalletBalance>
          <WalletBalanceLeft>
            <img src={WalletIcon} alt="Wallet-icon" />
            <div>
              <h3>Your wallet balance</h3>
              <p>Use it to create and run campaigns</p>
            </div>
          </WalletBalanceLeft>
          <h2>
            {user?.data?.currency
              ? String(user?.data?.currency).toLowerCase() === "inr" ||
                user?.data?.currency === "" ||
                user?.data?.currency === null
                ? "₹"
                : fetchSymbolFromCurrency(user?.data?.currency)
              : "₹"}
            {isWalletBalanceLoading
              ? "Loading..."
              : isSuccess
              ? Number(walletBalance?.balance).toFixed(2)
              : "0"}
          </h2>
        </WalletBalance>
        <PaymentFormControl>
          <FormLabel id="paymentmode">Payment Mode</FormLabel>
          <RadioGroup
            aria-labelledby="radio-buttons-group-label"
            name="radio-buttons-group"
            value={paymentMode}
            onChange={handleChange}
          >
            <PaymentFormControlLabel
              value="card"
              control={<Radio />}
              label="Credit Card (3% - 5% charges)"
            />
            <PaymentFormControlLabel
              value="bank_transfer"
              control={<Radio />}
              label="Wire Transfer"
            />
          </RadioGroup>
        </PaymentFormControl>
        <TextField
          placeholder="1,00,000"
          value={(Number(amount.replace(/\D/g, "")) || "").toLocaleString()}
          error={
            Number.isInteger(Number(amount.replace(/\,/g, ""))) ? false : true
          }
          helperText={
            Number.isInteger(Number(amount.replace(/\,/g, "")))
              ? null
              : "Enter a correct amount."
          }
          onChange={(e) => setAmount(e.target.value)}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          label="Enter amount"
          fullWidth
        />
        {amount && (
          <>
            <ProcessingFee>
              <h4>
                Overage Charges for $
                {Number(amount.replace(/\,/g, "")).toLocaleString()}
              </h4>
              {isLoading ? (
                <CircularProgress
                  style={styles.circularProgressStyle}
                  size={20}
                />
              ) : isGetProcessingFeeSuccess && !isLoading ? (
                <p>$ {Number(processingFee).toLocaleString()}</p>
              ) : (
                <p>$ 0</p>
              )}
            </ProcessingFee>
            <AmountTotal>
              <p>Amount to be paid</p>
              <h2>${totalAmt.toLocaleString()}</h2>
            </AmountTotal>
          </>
        )}

        {!amount && (
          <WalletNote>
            <img src={lockIcon} alt="lock-icon" />
            <p>
              Safe and secure payments. 100% authentic. Payment secured by
              stripe.
            </p>
          </WalletNote>
        )}
        <SubmitButton
          disabled={
            amount === "" ||
            !Number.isInteger(Number(amount.replace(/\,/g, ""))) ||
            isBtnDisabled ||
            isError ||
            isLoading
          }
          type="submit"
          onClick={AddMoneyThroughStripehandler}
        >
          {isBtnDisabled ? (
            <CircularProgress style={styles.circularProgressStyle} size={20} />
          ) : (
            "Add money now"
          )}
        </SubmitButton>

        <PaymentDetailsContainer
          open={showPaymentDetailsModal}
          onClose={() => setShowPaymentDetailsModal(false)}
        >
          <ModalBox>
            <CrossIconContainer>
              <img
                src={CloseCircleIcon}
                alt="cross-icon"
                onClick={() => setShowPaymentDetailsModal(false)}
              />
            </CrossIconContainer>
            <ModalBoxBody>
              <ModalBodyTop>
                <img src={CashImage} alt="cash-img" />
                <div>
                  <h3>Payment Details</h3>
                  <p>
                    Transfer money to the account below and please drop an email
                    to support@zocket.ai with the payment confirmation.
                  </p>
                </div>
              </ModalBodyTop>
              <hr />
              <ModalBodyDetails>
                <h3>Beneficiary</h3>
                <div>
                  <div>
                    <h4>Beneficiary Name: </h4>
                    <p>Zocket Technologies, Inc.</p>
                  </div>
                  <div>
                    <Tooltip
                      onClose={() => setDetailCopied(false)}
                      arrow
                      placement="right"
                      TransitionComponent={Zoom}
                      title={
                        <CustomToolTip>
                          {detailCopied ? "Copied!" : "Copy to Clipboard"}
                        </CustomToolTip>
                      }
                    >
                      <Button
                        onClick={() => {
                          navigator.clipboard.writeText(
                            "Zocket Technologies, Inc."
                          );
                          setDetailCopied(true);
                        }}
                      >
                        <ContentCopyIcon sx={{ color: "#fff" }} />
                      </Button>
                    </Tooltip>
                  </div>
                </div>
                <div>
                  <div>
                    <h4>Account Number: </h4>
                    <p>202422099324</p>
                  </div>
                  <div>
                    <Tooltip
                      onClose={() => setDetailCopied(false)}
                      arrow
                      placement="right"
                      TransitionComponent={Zoom}
                      title={
                        <CustomToolTip>
                          {detailCopied ? "Copied!" : "Copy to Clipboard"}
                        </CustomToolTip>
                      }
                    >
                      <Button
                        onClick={() => {
                          navigator.clipboard.writeText("202422099324");
                          setDetailCopied(true);
                        }}
                      >
                        <ContentCopyIcon sx={{ color: "#fff" }} />
                      </Button>
                    </Tooltip>
                  </div>
                </div>
                <div>
                  <div>
                    <h4>Type of Account: </h4>
                    <p>Checking</p>
                  </div>
                  <div>
                    <Tooltip
                      onClose={() => setDetailCopied(false)}
                      arrow
                      placement="right"
                      TransitionComponent={Zoom}
                      title={
                        <CustomToolTip>
                          {detailCopied ? "Copied!" : "Copy to Clipboard"}
                        </CustomToolTip>
                      }
                    >
                      <Button
                        onClick={() => {
                          navigator.clipboard.writeText("Checking");
                          setDetailCopied(true);
                        }}
                      >
                        <ContentCopyIcon sx={{ color: "#fff" }} />
                      </Button>
                    </Tooltip>
                  </div>
                </div>
                <div>
                  <div>
                    <h4>Beneficiary Address: </h4>
                    <p>
                      651 North Broad Street, Suite 201 Middletown, DE 19709
                    </p>
                  </div>
                  <div>
                    <Tooltip
                      onClose={() => setDetailCopied(false)}
                      arrow
                      placement="right"
                      TransitionComponent={Zoom}
                      title={
                        <CustomToolTip>
                          {detailCopied ? "Copied!" : "Copy to Clipboard"}
                        </CustomToolTip>
                      }
                    >
                      <Button
                        onClick={() => {
                          navigator.clipboard.writeText(
                            "651 North Broad Street, Suite 201 Middletown, DE 19709"
                          );
                          setDetailCopied(true);
                        }}
                      >
                        <ContentCopyIcon sx={{ color: "#fff" }} />
                      </Button>
                    </Tooltip>
                  </div>
                </div>
                <h3>Receiving Bank Details</h3>
                <div>
                  <div>
                    <h4>ABA Routing Number: </h4>
                    <p>091311229</p>
                  </div>
                  <div>
                    <Tooltip
                      onClose={() => setDetailCopied(false)}
                      arrow
                      placement="right"
                      TransitionComponent={Zoom}
                      title={
                        <CustomToolTip>
                          {detailCopied ? "Copied!" : "Copy to Clipboard"}
                        </CustomToolTip>
                      }
                    >
                      <Button
                        onClick={() => {
                          navigator.clipboard.writeText("091311229");
                          setDetailCopied(true);
                        }}
                      >
                        <ContentCopyIcon sx={{ color: "#fff" }} />
                      </Button>
                    </Tooltip>
                  </div>
                </div>
                <div>
                  <div>
                    <h4>Bank Name: </h4>
                    <p>Choice Financial Group</p>
                  </div>
                  <div>
                    <Tooltip
                      onClose={() => setDetailCopied(false)}
                      arrow
                      placement="right"
                      TransitionComponent={Zoom}
                      title={
                        <CustomToolTip>
                          {detailCopied ? "Copied!" : "Copy to Clipboard"}
                        </CustomToolTip>
                      }
                    >
                      <Button
                        onClick={() => {
                          navigator.clipboard.writeText(
                            "Choice Financial Group"
                          );
                          setDetailCopied(true);
                        }}
                      >
                        <ContentCopyIcon sx={{ color: "#fff" }} />
                      </Button>
                    </Tooltip>
                  </div>
                </div>
                <h3>
                  Mercury uses Choice Financial Group as a banking partner.
                </h3>
                <div>
                  <div>
                    <h4>Bank Address: </h4>
                    <p>4501 23rd Avenue S Fargo, ND 58104</p>
                  </div>
                  <div>
                    <Tooltip
                      onClose={() => setDetailCopied(false)}
                      arrow
                      placement="right"
                      TransitionComponent={Zoom}
                      title={
                        <CustomToolTip>
                          {detailCopied ? "Copied!" : "Copy to Clipboard"}
                        </CustomToolTip>
                      }
                    >
                      <Button
                        onClick={() => {
                          navigator.clipboard.writeText(
                            "4501 23rd Avenue S Fargo, ND 58104"
                          );
                          setDetailCopied(true);
                        }}
                      >
                        <ContentCopyIcon sx={{ color: "#fff" }} />
                      </Button>
                    </Tooltip>
                  </div>
                </div>
              </ModalBodyDetails>
            </ModalBoxBody>
          </ModalBox>
        </PaymentDetailsContainer>
      </Wrapper>
      {paymentMode === "card" ? (
        <ChargesContainer>
          <CreditCharges>
            <h4>2.9% + 30¢</h4>
            <p>per successful charge for domestic cards</p>
            <span>+ 0.5% for manually entered cards</span>
            <span>+ 1.5% for international cards</span>
            <span>+ 1% if currency conversion is required</span>
          </CreditCharges>
        </ChargesContainer>
      ) : (
        ""
      )}
    </WalletGlobalContainer>
  );
};

export default WalletDropDownGlobal;

const styles = createStyles({
  circularProgressStyle: {
    color: "white",
  },
});
