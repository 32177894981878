import { PreloadedState, configureStore } from "@reduxjs/toolkit";
import userReducer from "../slices/user/userSlice";
import userLocationReducer from "../slices/location/userlocationSlice";
import insightReducer from "../slices/insight/insightSlice";
import allCamapignInsightRedcuer from "../slices/allcampaignInsight/allCamapignInsightSlice";
import addCompanyDetailReducer from "src/slices/addCompanyDetail/adCompanyDetailSlice";
import liveAdCopiesReducer from "src/slices/liveAdCopies/liveAdCopiesSlice";
import reports from "src/slices/reports/reportsSlice";
import wallet from "src/slices/wallet/walletSlice";
import rules from "src/slices/rules/rules";
import { walletBalanceApi } from "src/api/wallet-balance";
import { invoiceApi } from "src/api/invoice";
import { subscriptionApi } from "src/api/subscription";
import { adAccountApi } from "src/api/ad-account";
import { userApi } from "src/api/user";
import { authenticationApi } from "src/api/authentication";
import { authenticationSlice } from "src/slices/authentication/authentication";
import { appSumoApi } from "src/api/appsumo";

export const store = configureStore({
  reducer: {
    user: userReducer,
    wallet: wallet,
    insight: insightReducer,
    allCampaignInsight: allCamapignInsightRedcuer,
    addCompanydetail: addCompanyDetailReducer,
    liveAdCopies: liveAdCopiesReducer,
    reports: reports,
    rules: rules,
    userLocation: userLocationReducer,
    authentication: authenticationSlice.reducer,
    [walletBalanceApi.reducerPath]: walletBalanceApi.reducer,
    [invoiceApi.reducerPath]: invoiceApi.reducer,
    [subscriptionApi.reducerPath]: subscriptionApi.reducer,
    [adAccountApi.reducerPath]: adAccountApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [authenticationApi.reducerPath]: authenticationApi.reducer,
    [appSumoApi.reducerPath]: appSumoApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(walletBalanceApi.middleware)
      .concat(invoiceApi.middleware)
      .concat(subscriptionApi.middleware)
      .concat(adAccountApi.middleware)
      .concat(authenticationApi.middleware)
      .concat(appSumoApi.middleware)
      .concat(userApi.middleware),
});

export default store;

export type Rootstate = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
