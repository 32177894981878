import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import UNIVERSAL from "../../config/config";
import moment from "moment";
import { api } from "src/libs/api";
import { reauthenticateUser } from "../authentication/authentication";

type InitialState = {
  data: any;
  prevInsight: any;
  afterInsight: any;
  prevDate: any;
  afterDate: any;
  startDate: Date | null;
  endDate: Date | null;
  comparison: boolean;
  loading: boolean;
  error: any;
};

type Preset = {
  val: string;
  string: string;
};

type GetAllCampaignInsightPayload = {
  euid: string;
  ad_account: string;
  campaign_list: any[];
  preset: string | "";
  date_start: Date;
  date_stop: Date;
};

const initialState: InitialState = {
  data: "",
  prevInsight: "",
  afterInsight: "",
  prevDate: "",
  afterDate: "",
  startDate: null,
  endDate: null,
  comparison: false,
  loading: false,
  error: "",
};

export const getPrevAllCampaignInsight = createAsyncThunk(
  "/allCampaignInsight/getPrevAllCampaignInsight",
  (data: GetAllCampaignInsightPayload, { rejectWithValue }) => {
    return api(`${UNIVERSAL.BASEURL}/api/v1/facebook/campaign_table`, {
      method: "POST",
      body: JSON.stringify({
        euid: data.euid,
        account_id: data.ad_account,
        campaigns: data.campaign_list,
        date_preset: data?.preset ? data?.preset : "",
        date_start: !data?.preset
          ? moment(data?.date_start).format("YYYY-MM-DD")
          : "" || "",
        date_stop: !data?.preset
          ? moment(data?.date_stop).format("YYYY-MM-DD")
          : "" || "",
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (!res.success) return rejectWithValue(res);
        if (res.success) return res;
      });
  }
);

export const getAfterAllCampaignInsight = createAsyncThunk(
  "/allCampaignInsight/getAfterallCampaignInsight",
  (data: GetAllCampaignInsightPayload, { rejectWithValue }) => {
    return api(`${UNIVERSAL.BASEURL}/api/v1/facebook/campaign_table`, {
      method: "POST",
      body: JSON.stringify({
        euid: data.euid,
        account_id: data.ad_account,
        campaigns: data.campaign_list,
        date_preset: data?.preset ? data?.preset : "",
        date_start: !data?.preset
          ? moment(data?.date_start).format("YYYY-MM-DD")
          : "" || "",
        date_stop: !data?.preset
          ? moment(data?.date_stop).format("YYYY-MM-DD")
          : "" || "",
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (!res.success) return rejectWithValue(res);
        if (res.success) return res;
      });
  }
);

export const getAllCampaignInsight = createAsyncThunk(
  "/allCampaignInsight/getallCampaignInsight",
  (data: GetAllCampaignInsightPayload, { rejectWithValue }) => {
    return api(`${UNIVERSAL.BASEURL}/api/v1/facebook/campaign_table`, {
      method: "POST",
      body: JSON.stringify({
        euid: data.euid,
        account_id: data.ad_account,
        campaigns: data.campaign_list,
        date_preset: data?.preset ? data?.preset : "",
        date_start: !data?.preset
          ? moment(data?.date_start).format("YYYY-MM-DD")
          : "" || "",
        date_stop: !data?.preset
          ? moment(data?.date_stop).format("YYYY-MM-DD")
          : "" || "",
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (!res.success) return rejectWithValue(res);
        if (res.success) return res;
      });
  }
);

const allCampaignInsightSlice = createSlice({
  name: "allCampaignInsight",
  initialState,
  reducers: {
    clearPrevAfter: (state) => {
      state.prevInsight = "";
      state.afterInsight = "";
      state.prevDate = "";
      state.afterDate = "";
    },
    clearStoreStartEnd: (state) => {
      state.startDate = null;
      state.endDate = null;
    },
    setPrevDate: (state, action) => {
      state.prevDate = action.payload;
    },
    setAfterDate: (state, action) => {
      state.afterDate = action.payload;
    },
    setComparison: (state, action) => {
      state.comparison = action.payload;
    },
    setStoreStartDate: (state, action) => {
      state.startDate = action.payload;
    },
    setStoreEndDate: (state, action) => {
      state.endDate = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(reauthenticateUser, () => initialState);
    builder.addCase(getAllCampaignInsight.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllCampaignInsight.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
      state.error = "";
    });
    builder.addCase(getPrevAllCampaignInsight.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPrevAllCampaignInsight.fulfilled, (state, action) => {
      state.loading = false;
      state.prevInsight = action.payload.data;
      state.error = "";
    });
    builder.addCase(getAfterAllCampaignInsight.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAfterAllCampaignInsight.fulfilled, (state, action) => {
      state.loading = false;
      state.afterInsight = action.payload.data;
      state.error = "";
    });
    builder.addCase(getAllCampaignInsight.rejected, (state, action) => {
      state.loading = false;
      state.data = "";
      state.error = action?.payload;
    });
  },
});

export default allCampaignInsightSlice.reducer;
export const {
  clearPrevAfter,
  setPrevDate,
  setAfterDate,
  setComparison,
  setStoreStartDate,
  setStoreEndDate,
  clearStoreStartEnd,
} = allCampaignInsightSlice.actions;
