import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import UNIVERSAL from "../../config/config";
import { api } from "src/libs/api";
import { reauthenticateUser } from "../authentication/authentication";

type InitialState = {
  data: any;
  automationResult: any;
  adDipping: any;
  totalAdSpends: string;
  loading: boolean;
  error: any;
};

type SaveRulePayload = {
  ad_account_id: string;
  euid: string;
  rule_set: RuleSet[];
};

type Rule = {
  lop: string | null;
  param: string;
  cop: string;
  val: number;
};

type RuleSet = {
  name: string;
  rule_type: string;
  is_enabled: boolean;
  rules: Rule[];
};

const initialState: InitialState = {
  data: "",
  automationResult: "",
  adDipping: "",
  totalAdSpends: "0",
  loading: true,
  error: "",
};

type GetRulesPayload = {
  ad_account_id: string;
  euid: string;
};

export const saveRule = createAsyncThunk(
  "/rules/saveRule",
  (data: SaveRulePayload, { rejectWithValue }) => {
    return api(`${UNIVERSAL.BASEURL}/api/v1/facebook/campaigns_rules`, {
      method: "POST",
      body: JSON.stringify({
        ...data,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (!res.success) return rejectWithValue(res);
        if (res.success) return res;
      });
  }
);

export const getRule = createAsyncThunk(
  "/rules/getRules",
  (data: GetRulesPayload, { rejectWithValue }) => {
    return api(`${UNIVERSAL.BASEURL}/api/v1/facebook/get_campaign_rules`, {
      method: "POST",
      body: JSON.stringify({
        ...data,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (!res.success) return rejectWithValue(res);
        if (res.success) return res;
      });
  }
);

type UpdateRulePayload = {
  ad_account_id: string;
  euid: string;
  id: number;
  rule_set: any[];
};

export const updateRule = createAsyncThunk(
  "/rules/updateRules",
  (data: UpdateRulePayload, { rejectWithValue }) => {
    return api(`${UNIVERSAL.BASEURL}/api/v1/facebook/update_campaign_rules`, {
      method: "POST",
      body: JSON.stringify({
        ...data,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (!res.success) return rejectWithValue(res);
        if (res.success) return res;
      });
  }
);

type AutomationResultPayload = {
  euid: string;
  ad_account_id: string;
};

export const getAutomationResult = createAsyncThunk(
  "/rules/automationResult",
  (data: AutomationResultPayload, { rejectWithValue }) => {
    return api(`${UNIVERSAL.BASEURL}/api/v1/facebook/automation_rules_result`, {
      method: "POST",
      body: JSON.stringify({
        ...data,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (!res.success) return rejectWithValue(res);
        if (res.success) return res;
      });
  }
);

type AdsDippingPayload = {
  ad_account_id: string;
};

export const getAdDippings = createAsyncThunk(
  "/rules/getAdDippings",
  (data: AdsDippingPayload, { rejectWithValue }) => {
    return api(
      `${UNIVERSAL.BASEURL}/api/v1/facebook/low_performance_campaigns`,
      {
        method: "POST",
        body: JSON.stringify({
          ...data,
        }),
      }
    )
      .then((response) => response.json())
      .then((res) => {
        if (!res.success) return rejectWithValue(res);
        if (res.success) return res;
      });
  }
);

type TotalAdSpendPayload = {
  ad_account_id: string;
};

export const getTotalAdSpends = createAsyncThunk(
  "/rules/totalAdSpends",
  (data: TotalAdSpendPayload, { rejectWithValue }) => {
    return api(`${UNIVERSAL.BASEURL}/api/v1/facebook/total_ads_spend`, {
      method: "POST",
      body: JSON.stringify({
        ...data,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (!res.success) return rejectWithValue(res);
        if (res.success) return res;
      });
  }
);

const rules = createSlice({
  name: "rules",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(reauthenticateUser, () => initialState);
    builder.addCase(getTotalAdSpends.fulfilled, (state, action) => {
      state.loading = false;
      state.totalAdSpends = action.payload.data;
      state.error = "";
    });
    builder.addCase(getTotalAdSpends.rejected, (state, action) => {
      state.loading = false;
      state.totalAdSpends = "0";
      state.error = "";
    });
    builder.addCase(saveRule.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(saveRule.fulfilled, (state, action) => {
      state.loading = false;
      // state.data = action.payload.data
      state.error = "";
    });
    builder.addCase(saveRule.rejected, (state, action) => {
      state.loading = false;
      state.data = "";
      state.error = action?.payload;
    });

    builder.addCase(getAdDippings.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAdDippings.fulfilled, (state, action) => {
      state.loading = false;
      state.adDipping = action.payload.data;
      state.error = "";
    });
    builder.addCase(getAdDippings.rejected, (state, action) => {
      state.loading = false;
      state.data = "";
      state.error = action?.payload;
    });

    builder.addCase(updateRule.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateRule.fulfilled, (state, action) => {
      state.loading = false;
      // state.data = action.payload.data
      state.error = "";
    });
    builder.addCase(updateRule.rejected, (state, action) => {
      state.loading = false;
      // state.data = '';
      state.error = action?.payload;
    });

    builder.addCase(getRule.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getRule.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
      state.error = "";
    });
    builder.addCase(getRule.rejected, (state, action) => {
      state.loading = false;
      state.data = "";
      state.error = action?.payload;
    });
    builder.addCase(getAutomationResult.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAutomationResult.fulfilled, (state, action) => {
      state.loading = false;
      state.automationResult = action.payload.data;
      state.error = "";
    });
    builder.addCase(getAutomationResult.rejected, (state, action) => {
      state.loading = false;
      state.automationResult = "";
      state.error = action?.payload;
    });
  },
});

export default rules.reducer;
