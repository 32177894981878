import {
  FetchArgs,
  FetchBaseQueryArgs,
} from "@reduxjs/toolkit/dist/query/fetchBaseQuery";
import { BaseQueryApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import UNIVERSAL, { apiAsyncMutex } from "src/config/config";
import store, { Rootstate } from "src/config/store";
import { authKey } from "src/constants/storage";
import {
  reauthenticateUser,
  updateOAuth2Tokens,
} from "src/slices/authentication/authentication";
import { ApiSuccess } from "src/types/api";
import { SecurityResponse } from "src/types/authentication";

const handleLogout = async () => {
  localStorage.removeItem(authKey);
  store.dispatch(reauthenticateUser());
  window.location.replace("/");
};

export function createFetchBaseQueryWithReauthentication(
  baseArgs: FetchBaseQueryArgs
) {
  const baseQuery = fetchBaseQuery({
    ...baseArgs,
    prepareHeaders: (headers, api) => {
      const exists = headers.has("Authorization");
      if (exists) return;
      const state = api.getState() as Rootstate;
      const accessToken = state.authentication.security?.access_token;
      headers.append("Authorization", `Bearer ${accessToken}`);
    },
  });

  return async function fetchBaseQueryWithReauthentication(
    args: string | FetchArgs,
    api: BaseQueryApi,
    extraOptions: any
  ) {
    await apiAsyncMutex.waitForUnlock();
    let result = await baseQuery(args, api, extraOptions);
    if (result.error?.status === 401) {
      if (
        result.meta?.request.url.includes(
          `${UNIVERSAL.BASEURL}/api/v1/auth/refresh_token`
        )
      ) {
        await handleLogout();
      } else {
        if (!apiAsyncMutex.isLocked()) {
          const release = await apiAsyncMutex.acquire();
          try {
            const state = api.getState() as Rootstate;
            const refreshToken = state.authentication.security?.refresh_token;
            const refreshTokenExpiry =
              state.authentication.security?.refresh_token_expiry;
            if (!refreshToken || !refreshTokenExpiry) {
              await handleLogout();
            } else {
              try {
                const query = await baseQuery(
                  {
                    url: `${UNIVERSAL.BASEURL}/api/v1/auth/refresh_token`,
                    method: "POST",
                    body: { refresh_token: refreshToken },
                  },
                  api,
                  extraOptions
                );
                const response = query.data as ApiSuccess<SecurityResponse>;
                api.dispatch(updateOAuth2Tokens(response.data));
                release();
                result = await baseQuery(args, api, extraOptions);
              } catch (error) {
                await handleLogout();
              }
            }
          } finally {
            release();
          }
        } else {
          await apiAsyncMutex.waitForUnlock();
          result = await baseQuery(args, api, extraOptions);
        }
      }
    }

    return result;
  };
}

export async function api(
  input: RequestInfo | URL,
  init?: RequestInit
): Promise<Response> {
  // Wait for the mutex to unlock
  await apiAsyncMutex.waitForUnlock();
  // Get the security token from the store
  const authentication = store.getState().authentication.security;
  // Make the request
  let response = await fetch(input, {
    ...init,
    headers: { Authorization: `Bearer ${authentication?.access_token}` },
  });
  // Check if the response is not ok
  if (!response.ok) {
    // Check if the response is a 401
    if (response.status === 401) {
      if (
        input
          .toString()
          .includes(`${UNIVERSAL.BASEURL}/api/v1/auth/refresh_token`)
      ) {
        // Logout the user since the user tried to refresh the token and it failed that means the refresh token is invalid or expired
        handleLogout();
      } else {
        // Check if the mutex is locked
        if (!apiAsyncMutex.isLocked()) {
          // Acquire the lock
          const release = await apiAsyncMutex.acquire();
          try {
            // Try to refresh the token
            try {
              const authorization = await fetch(
                `${UNIVERSAL.BASEURL}/api/v1/auth/refresh_token`,
                {
                  method: "POST",
                  body: JSON.stringify({
                    refresh_token: authentication?.refresh_token,
                  }),
                }
              );
              // Check if the response is not ok
              if (!authorization.ok) {
                // Logout the user since the there was an error while trying to refresh the token
                handleLogout();
              } else {
                // Parse the response
                const tokens = await authorization.json();
                // Update the tokens in the store
                store.dispatch(updateOAuth2Tokens(tokens.data));
                // Release the lock
                release();
                // Retry the original request
                response = await api(input, init);
              }
            } catch (error) {
              // Release the lock
              release();
              // Logout the user since the there was an error while trying to refresh the token
              handleLogout();
            }
          } finally {
            // Release the lock
            release();
          }
        } else {
          // Wait for the mutex to unlock since refresh token request is already in progress
          await apiAsyncMutex.waitForUnlock();
          // Retry the original request
          response = await api(input, init);
        }
      }
    }
  }

  return response;
}
