import { useEffect } from "react";

// Extend the Window interface to include the $crisp property
interface Window {
  $crisp: any[]; // Consider specifying a more precise type if known
  CRISP_WEBSITE_ID: string;
  location: Location;
}

declare const window: Window;

// Utility function to get URL parameters
function getUrlVars(): Record<string, string> {
  const vars: Record<string, string> = {};
  window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m, key, value) => {
    vars[key] = value;
    return m;
  });
  return vars;
}

// Custom hook to initialize Crisp Chat
const useCrispChat = (crispID: string) => {
  useEffect(() => {
    if (crispID) {
      console.log("crispid");
      // Initialize $crisp if it's not already initialized
      if (!window.$crisp) {
        window.$crisp = [];
      }

      // Set the Crisp website ID
      window.CRISP_WEBSITE_ID = crispID;

      // Load the Crisp script
      (function () {
        const d = document;
        const s = d.createElement("script");

        s.src = "https://client.crisp.chat/l.js";
        s.async = true;
        s.defer = true;

        d.getElementsByTagName("head")[0].appendChild(s);
      })();

      // Collect Crisp keys from URL parameters
      const crisp_keys: [string, string][] = [];
      const urlVars = getUrlVars();

      [
        "utm_source",
        "utm_medium",
        "utm_campaign",
        "utm_term",
        "utm_content",
      ].forEach((source) => {
        if (urlVars[source]) {
          crisp_keys.push([source, urlVars[source]]);
        }
      });

      // Set session data if any keys are found
      if (crisp_keys.length > 0) {
        window.$crisp.push(["set", "session:data", [crisp_keys]]);
      }

      window.$crisp.push(["do", "chat:show"]);
      window.$crisp.push(["config", "sound:mute", [false]]);
    } else {
      if (window.$crisp) {
        window.$crisp.push(["do", "chat:hide"]);
        window.$crisp.push(["config", "sound:mute", [true]]);
      }
    }
  }, [crispID]);
};

export default useCrispChat;
